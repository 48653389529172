import { socketConnect } from '../socketConnect';

export const videoUpload = (props) => {
  console.log('reached video upload');
  console.log('videoload props _id',props._id)
    // Create a new WebSocket connection
   const socket = socketConnect();

    if (socket.connected) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const fileArrayBuffer = new Uint8Array(fileReader.result);
          
           const obj = { fileArrayBuffer : fileArrayBuffer, productID: props._id}
           socket.timeout(600000).emit("video", obj);
           socket.on("uploadSuccess", (timestamp) => { 
            console.log(timestamp);
            props.onResponse({ success: true, message: "Video uploaded successfully" })          
          })
        };
        fileReader.readAsArrayBuffer(props.video);
    }
}
