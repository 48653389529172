import {
    createSlice,
    createAsyncThunk,
    
} from "@reduxjs/toolkit";
import axios from "axios";

const POSTS_URL = 'https://backend.bankajewellers.com/api';


export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const productCategorySlice = createSlice({
    name: 'categoryStore',
    initialState:{
        data:[],
        status:''
    },
    
    reducers: {
        // setProducts(state, action) {
        //     state.data = action.payload;
        // },
        // setStatus(state, action) {
        //     state.status = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addProductCategory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(addProductCategory.fulfilled, (state, action) => {
              //  console.log('ok',action.payload);                
              state.data.push(action.payload);
                state.status = STATUSES.IDLE;
            })
            .addCase(addProductCategory.rejected, (state, _action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(updateProductCategory.pending, (state, action) => {
                
                state.status = STATUSES.LOADING;
            })
            .addCase(updateProductCategory.fulfilled, (state, action) => {
              //  console.log('ok',action.payload);
                const existingItem = state.data.find((item) => item._id === action.payload._id);                
                if(existingItem){
                    existingItem.categoryName=action.payload.categoryName
                }
            
                state.status = STATUSES.IDLE;
            })
            .addCase(updateProductCategory.rejected, (state, _action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(getProductCategory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProductCategory.fulfilled, (state, action) => {               
              state.data=action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(getProductCategory.rejected, (state, _action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(deleteProductCategory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(deleteProductCategory.fulfilled, (state, action) => {
               
                state.data = state.data.filter((item) => item._id !== action.payload._id);
             // state.data=action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(deleteProductCategory.rejected, (state, _action) => {
                state.status = STATUSES.ERROR;
            })
            
    },
});

export const { setProductCategory, setStatus } = productCategorySlice.actions;
export default productCategorySlice.reducer;

// Thunks
export const addProductCategory = createAsyncThunk('productCategory/add', async (addCategory) => {
    const res = await axios.post(`${POSTS_URL}/productCategory`,addCategory);
  //  console.log(res)
   // const data = await res.json();
   const data = res.data;
   // console.log(data);
    return data.result;
});
export const updateProductCategory = createAsyncThunk('productCategory/update', async (updateCategory) => {
  //  console.log('update thunk before sending axios request',updateCategory);
    const res = await axios.patch(`${POSTS_URL}/productCategory`,updateCategory);
  //  console.log(res)
   // const data = await res.json();
   const data = res.data;
  //  console.log('thunk update category',data);
    return data.result;
});
export const deleteProductCategory = createAsyncThunk('productCategory/delete', async (deleteCategory) => {
   // console.log('delete thunk before sending axios request',deleteCategory);
    const res = await axios.delete(`${POSTS_URL}/productCategory/delete/${deleteCategory._id}`);
  //  console.log(res)
   // const data = await res.json();
   const data = res.data;
  //  console.log('thunk update category',data);
    return data.result;
});
export const getProductCategory = createAsyncThunk('productCategory/get', async () => {
    const res = await axios.get(`${POSTS_URL}/productCategory`);
  //  console.log(res)
   // const data = await res.json();
   const data = res.data;
    console.log('Get product category thunk',data);
    return data.categories;
});




// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
