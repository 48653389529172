import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { add,deduct,remove } from '../store/cartSlice';


import { Table, Thead, Tbody,Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";


const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {data:cartProducts }= useSelector((state) => state.cart);
    console.log('cart page products',cartProducts)

    const handleRemove = (e,productId) => {
        e.preventDefault();
        dispatch(remove(productId));
    };
    const deductQuantity=(e,_id)=>{
        e.preventDefault();
        console.log(_id)
        dispatch(deduct(_id))
            
    }
    const addQuantity=(e,_id)=>{
        e.preventDefault();
        console.log(_id)
        const product = cartProducts.filter(item=>item._id === _id);
      //console.log('product',product)
      dispatch(add(product[0]));            
    }
    /*
    const cartSubTotal=()=>{
        let subTotal=0;
        cartProducts.map((product) =>{
            subTotal += product.totalPrice;
            });
            return subTotal.toFixed(2);
    }*/
    const cartSubTotal = (cartProducts) => {
        return cartProducts !== undefined && cartProducts.reduce((accumulator, product) => {
          return accumulator + product.totalPrice;
        }, 0).toFixed(2) || 0;
      };
      
      
      
    const checkoutHandler=()=>{
        navigate('/login?redirect=/shipping');

    }

    return (
        
        <div className="container cart-container">
            <h3>Cart</h3>
            <Table className="cartWrapper">
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>Product Name</Th>
                        <Th>Price</Th>
                        <Th>quantity</Th>
                        <Th>Amount</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                {cartProducts.map((product) => (
                    <Tr key={product._id} className="cartCard">
                        <Td>
                        <img src={`https://backend.bankajewellers.com/products/images/mobile-${product.image}`} 
                        alt=""
                        style={{maxWidth:'120px'}}
                        />
                        </Td>
                        <Td>
                        <h5>{product.name}</h5>
                        </Td>
                        <Td>
                        <h5>{product.price.toFixed(2)}</h5>
                        </Td>
                        <Td>
                        <h5>   
                        <div className='deduct-qty'
                        style={{backgroundColor:'#000',
                        color:'#ffffff',
                        padding:'5px',
                        width:'40px',
                        display:'block',
                        float:'left',
                        textAlign:'center',
                        cursor:'pointer'
                    }} 
                        onClick={(e)=>deductQuantity(e,product._id)}>-</div>
                        <div
                        style={{
                        padding:'5px',
                        width:'40px',
                        display:'block',
                        float:'left',
                        textAlign:'center'
                    }}
                        >{product.quantity}</div>
                        <div 
                        className='add-qty' 
                        style={{backgroundColor:'#000',color:'#ffffff',
                        padding:'5px',
                        width:'40px',
                        display:'block',
                        float:'left',
                        textAlign:'center',
                        cursor:'pointer'
                    }} 
                        onClick={(e)=>addQuantity(e,product._id)}>+</div>
                        </h5>
                        </Td>
                        <Td className='mt-3'>
                        <h5>{product.totalPrice.toFixed(2)}</h5>
                        </Td>

                        
                        <Td>
                        <button
                            className="btn"
                            onClick={(e) => handleRemove(e,product._id)}
                        >
                            Remove
                        </button>
                        </Td>
                    </Tr>
                ))}
                </Tbody>
                
            </Table>
            <div className='cart-footer'>
               <div>
                <h5 className='mt-2 subtotal'>Sub Total &#8377;{cartSubTotal()}
                       </h5>   
                       </div>
            <div>
            <button
                type="button"
                onClick={checkoutHandler}
                className="c-button c-button--accent cart-button pe-3"
                disabled={cartProducts.length === 0}
              >
                Proceed to Checkout
              </button>
              </div>
              </div>
             
              
        </div>
    );
};

export default Cart;
