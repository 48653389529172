import React, { useState } from "react";



import { Modal } from "react-bootstrap";
import ProductsGrid from "./ProductsGrid";
import PriceFilter from "./PriceFilter";
import { FaFilter } from "react-icons/fa";
import styles from "./shop.module.css";

const Shop = () => {
 // const dispatch = useDispatch();
  /*
  const { data: categoryStore, Status } = useSelector(
    (state) => state.categoryStore
  );*/
 // const [selectedOption, setSelectedOption] = useState(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [minPrice] = useState(500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [maxPrice] = useState(200000);
  const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);

  const handlePriceChange = (priceRange) => {
    setPriceRange(priceRange);
  };
  console.log("price range", priceRange);
  /*
  useEffect(() => {
    dispatch(getProductCategory());
  }, []);
*/
  
  
  /*
  const handleSelected = (e, item) => {
    e.preventDefault();
    console.log(item);
    setSelectedOption(item);
  };
  
  const handleClearFilter = (e) => {
    e.preventDefault();
    console.log("handlerclearfilter");

    setSelectedOption(null, () => {
      console.log("selected option inside clearFilter", selectedOption);
    });
  };*/
  const handleFilterView = (e) => {
    e.preventDefault();
    console.log("handleFilterView");
    setIsModalOpen(true);
  };
/*
  const options = [];
  categoryStore.forEach((item) => {
    options.push({ value: item.categoryName, label: item.categoryName });
  });
*/
  return (
    <div className={`${styles.shop} row`}>
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <div style={{ marginTop: "20px", padding: "20px" }}>
              <div className={`${styles.filterHeading} mb-3`}>
                <h4>Price Filter</h4>
              </div>

              <PriceFilter
                style={{ marginTop: "50px" }}
                minPrice={minPrice}
                maxPrice={maxPrice}
                onPriceChange={handlePriceChange}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      <div className='product-grid col-lg-12'>
        <div
          onClick={(e) => handleFilterView(e)}
          className={styles.filterButton}
          style={{ cursor: "pointer", marginBottom: "10px" }}
        >
          <FaFilter title='Filter' />
          Filter
        </div>

        <ProductsGrid
          
          priceRange={priceRange}
        />
      </div>
    </div>
  );
};

export default Shop;
