import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { add } from '../store/cartSlice';
import { fetchProducts } from '../store/productSlice';
import { Link } from 'react-router-dom';
import './ProductGrid.css';

const NewProducts = () => {
    const dispatch = useDispatch();
    const { data: productStore } = useSelector((state) => state.productStore);
   // const result = useSelector((state) => state.product);
    // const [products, setProducts] = useState([]);
   // console.log('state.product',productStore)

    useEffect(() => {
        dispatch(fetchProducts());
        
    },[dispatch,productStore.data] );

    

    const newProducts= productStore.filter(function(item) {
      if (this.count < 6) {
        this.count++;
        return true;
      }
      return false;
    }, {count: 0});
    const newProductsSorted = newProducts.sort((a, b) =>  new Date(b.createdAt) -  new Date(a.createdAt));
    const addToCartHandler=(event,id)=>{
      event.preventDefault();      
      const product = productStore.filter(item=>item._id === id);
      console.log('product',product)
      dispatch(add(product[0]));
      toast.success('item added to cart')

    }

    return (
        <div className="row">
            {newProductsSorted !=='' && newProductsSorted.map((product) => (
            <div key={product._id} className="col-lg-3 item" >              
              <Link to={`/product-detail/${product.slug}`}>
                <img className="product-img" 
                style={{width:'100%',backgroundColor:'#f7f7f7'}}
                src={`https://backend.bankajewellers.com/products/images/${product.images[0]!==undefined?product.images[0].filename:''}`} alt={product.name} />
                <div className='add-to-cart' onClick={e=>addToCartHandler(e,product._id)}>Add to Cart</div>
                </Link>
                <div className="card-body">
        <Link to={`/product/${product.category[0]}`} className="product-category">
          {product.category[0]}
        </Link>
        <Link to={`/product-detail/${product.slug}`}>
          <h2 className="product-title">{product.name}</h2>
        </Link>
        <Link to={`/product-detail/${product.slug}`}>
          <p className="product-desc">{product.description}</p>
        </Link>
        
        <div className="row">
          <div className="price">&#8377;{product.price}</div>          
        </div>
      </div>
            </div>
          ))}
           
        </div>
    );
};

export default NewProducts;
