import React from 'react';
import HomeCarousel from '../components/HomeCarousel';
import '../components/home-section1.css';
import HomeTabbed from '../components/HomeTabbed';


const Home = () => {
    return (
        <div >
            <HomeCarousel />
            <div className='l-inner'>          
            
            </div>
            <section style={{marginTop:"100px",marginBottom:"100px"}}>             
                
                <HomeTabbed />
            </section>
        </div>
    );
};

export default Home;
