import React from 'react';
import { Route, Routes } from 'react-router-dom'
//import AddressList from './AddressList';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import OrderHistory from './OrderHistory';
import UserMenu from './UserMenu'
import UserRoute from './UserRoute';
import styles from './UserDashboard.module.css';
import ShippingAddressesDashboard from './ShippingAddressesDashboard';
import UpdateProfile from './UpdateProfile';
import MyProfile from './MyProfile';


const UserLayout = () => {
  return (
    <div className='row'>
      
      <MainHeader />  
        <div className={`${styles.userMenu} col-2`}>
        <UserMenu />
        </div>
        <div className={`${styles.userContent} col-10`}>
        
        <Routes>
        <Route element={<UserRoute />}>         
        
          <Route path='/' element={ <MyProfile />} />
          <Route path='/update-profile' element={ <UpdateProfile />} />
          <Route path='/order-history' element={<OrderHistory />} />
          <Route path='/address-list' element={<ShippingAddressesDashboard />} />
                 
        </Route>
        </Routes>
        
     
        </div>
        <MainFooter />
        </div>
  )
}

export default UserLayout