import React from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { logout } from "../../store/userSlice";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import 'react-pro-sidebar/dist/css/styles.css';


const SideMenu = () => {
  const dispatch=useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <ProSidebar style={{minWidth:'auto',width:'auto'}}>
  <Menu iconShape="square">
  <MenuItem >
  Dashboard
  <Link to="/admin/dashboard" />
</MenuItem>

    <SubMenu title="Products"  >
     
      <MenuItem>Add Category<Link to="/admin/add-category" /></MenuItem>
      
      <MenuItem>Product List<Link to="/admin/products" /></MenuItem>
      
    </SubMenu>
    <MenuItem>Orders List<Link to="/admin/orders" /></MenuItem>
    <MenuItem>Users List<Link to="/admin/users" /></MenuItem>
    <MenuItem onClick={handleLogout}>Logout<Link to="/" /></MenuItem>
  </Menu>
</ProSidebar>
  );
};

export default SideMenu;
