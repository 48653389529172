import React from "react";
import {
  fab,
  faFacebookF,
  faInstagramSquare,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../store/userSlice";
import { Nav } from 'react-bootstrap';

import { NavLink } from "react-router-dom";
import {
  faBagShopping,
  faMagnifyingGlass,
  faPhone,
  faBars,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import {
  faUser,
  faHeart,
  faClockFour,
  faRectangleXmark,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";



library.add(fab, faPhone, faClockFour, faBars, faRectangleXmark);

const MainHeader = () => {
  const dispatch = useDispatch();  
  const { data: user } = useSelector((state) => state.user);
  const { data: cart } = useSelector((state) => state.cart);
  
  
  const handleLogout = () => {
    dispatch(logout());
  };
  
  const handleMobileMenu = (event) => {
    console.log(event.target.classList);
    const mMenu = document.getElementById(
      "c-header__menu--header-type-mobile-2"
    );
    mMenu.classList.add("c-header__menu--active");
  };
  const closeMobileMenu = (event) => {
    const mMenu = document.getElementById(
      "c-header__menu--header-type-mobile-2"
    );
    mMenu.classList.remove("c-header__menu--active");
  };
  return (
    <header className='l-section l-header' id='main-header'>
      <div
        className='c-header__outer c-header__outer--mobile c-header__outer--header-type-1 c-header__outer--header-type-mobile-2'
        style={{ minHeight: "61px" }}
      >
        <div
          className='c-header c-header--header-type-1 c-header--header-type-mobile-2 
          c-header--buttons-1 c-header--mobile js-header-mobile c-header--init init'
        >
          <div className='c-header__row'>
            <button
              onClick={handleMobileMenu}
              className='h-cb h-cb--svg c-header__menu-button js-mobile-menu-open'
              type='button'
            >
              <FontAwesomeIcon
                className='c-header__menu-button-icon'
                icon='fa fa-bars'
              />
            </button>
            <div className='c-header__logo c-header__logo--mobile'>
            <Nav.Link as={Link} to='/' className='c-header__logo-link'>
                BANKA JEWELLERS
              </Nav.Link>
            </div>

            <div className='c-header__col-mobile-buttons c-header__col-mobile-buttons--1'>
              <div className='c-header__cart c-header__cart--default js-cart'>
                <NavLink to='/cart/'
                  className='c-header__button-link js-cart-sidebar-open'
                  
                >
                  <FontAwesomeIcon
                    icon={faBagShopping}
                    className='h-hide-desktop'
                  />
                  <span className='js-cart-info'>
                  <span className="c-header__cart-count js-cart-count">
                    {cart !== undefined && cart.map(item=>item.quantity).reduce((sum,val)=>sum + val,0)}
                    </span>
                    </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='c-shop-sidebar c-shop-sidebar--mobile-only js-cart-sidebar'
        style={{ transform: "translateY(0px)", maxHeight: "calc(100% + 0px)" }}
      >
        <div className='c-shop-sidebar__wrap js-cart-sidebar-wrap'>
          <div className='c-shop-sidebar__buttons'>
            <button
              type='button'
              className='h-cb h-cb--svg c-shop-sidebar__close js-cart-sidebar-close'
            >
              <FontAwesomeIcon icon='far fa-rectangle-xmark' />
            </button>
          </div>
          <div className='c-shop-sidebar__content c-shop-sidebar__content--popup'>
            <div className='widget_shopping_cart_content'>
              <div className='c-product-list-widget__empty woocommerce-mini-cart__empty-message'>
                No products in the cart.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id='c-header__menu--header-type-mobile-2'
        className='c-header__menu c-header--mobile c-header__menu--header-type-mobile-2 js-mobile-menu'
        style={{
          transform: "translateY(0px)",
          maxHeight: "calc(100% + 0px)",
        }}
      >
        <div className='c-header__menu-shadow'></div>
        <div className='c-header__menu-buttons'>
          <button
            type='button'
            className='h-cb h-cb--svg c-header__menu-back js-mobile-menu-back'
          ></button>
          <button
            type='button'
            onClick={closeMobileMenu}
            className='h-cb h-cb--svg c-header__menu-close js-mobile-menu-close'
          >
            <FontAwesomeIcon icon={faRectangleXmark} />
          </button>
        </div>
        <div className='c-header__menu-content'>
          <div className='c-header__menu-wrap js-mobile-menu-wrap'>
            <div className='c-header__menu-page js-menu-page' data-page='0'>
              <ul id='mobile-top-menu' className='c-mobile-menu__list'>
                <li
                  id='menu-item-207'
                  className='c-mobile-menu__item menu-item-207 c-mobile-menu__item--has-children'
                >
                  <NavLink to='/' onClick={closeMobileMenu}>Home</NavLink>
                </li>
                <li
                  id='menu-item-208'
                  className='c-mobile-menu__item c-mobile-menu__item--has-children menu-item-208'
                >
                  <NavLink to='/shop/' onClick={closeMobileMenu}>Shop</NavLink>
                </li>
                <li
                  id='menu-item-209'
                  className='c-mobile-menu__item c-mobile-menu__item--has-children menu-item-209'
                >
                  <NavLink href='/blog/'>Blog</NavLink>
                </li>
                <li
                  id='menu-item-210'
                  className='c-mobile-menu__item c-mobile-menu__item--has-children menu-item-210'
                >
                  <NavLink>Pages</NavLink>
                </li>
                <li
                  id='menu-item-2318'
                  className='c-mobile-menu__item menu-item-2318 c-mobile-menu__item--has-children'
                >
                  <NavLink>Mega</NavLink>
                </li>
                <li
                  id='menu-item-223'
                  className='c-mobile-menu__item menu-item-223'
                >
                  <NavLink href='/contacts/'>Contacts</NavLink>
                </li>
                
                    
                  {user.token  && <li
                  id='menu-item-224'
                  className='c-mobile-menu__item menu-item-224'
                >
                  <NavLink to='/profile/' onClick={handleLogout} 
                  style={{ cursor: "pointer" }}>Hi {user.name},
                  <span>logout</span></NavLink>
                      </li>}
                   
                  {user.token && <li
                  id='menu-item-224'
                  className='c-mobile-menu__item menu-item-224'
                > <NavLink to='/login/' onClick={closeMobileMenu} 
                style={{ cursor: "pointer" }}>
                      <span>login</span>
                    </NavLink>
                    </li>}
                 
                <li className='c-mobile-menu__item-space'></li>
                <li className='c-mobile-menu__item c-mobile-menu__item--blocks'>
                  <div className='c-header__top js-mobile-blocks'>
                    <div className='c-header__top-row-list'>
                      <div className='c-header__top-row-item c-header__top-row-item--phone'>
                        <FontAwesomeIcon
                          icon={faPhone}
                          className='ip-phone c-header__top-row-icon c-header__top-row-icon--phone'
                        />
                        <a href='tel:+91 9437053975'>+91 9437053975</a>
                      </div>
                      <div className='c-header__top-row-item c-header__top-row-item--email'>
                        <a href='mailto:info@bankajewellers.com'>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className='c-header__top-row-icon'
                          />
                          info@bankajewellers.com
                        </a>
                      </div>
                      <div className='c-header__top-row-item c-header__top-row-item--address'>
                        <FontAwesomeIcon
                          icon={faLocation}
                          className='c-header__top-row-icon'
                        />
                        Banka Gali, BaidyanathChowk, Sambalpur
                      </div>
                      <div className='c-header__top-row-item c-header__top-row-item--hours'>
                        <FontAwesomeIcon
                          icon={faClockFour}
                          className='c-header__top-row-icon'
                        />
                        Mon-Sat: 10:00 - 20:00
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className='c-header__cart c-header__cart--default js-cart'>
              <NavLink
                className='c-header__button-link js-cart-sidebar-open'
                to='/cart/'
              >
                <FontAwesomeIcon
                  icon={faBagShopping}
                  className='h-hide-desktop'
                />
                <span className='js-cart-info'>
                <span className="c-header__cart-count js-cart-count">
                {cart !==undefined &&
                  <span className="c-header__cart-count js-cart-count">
                   { cart.map(item=>item.quantity).reduce((sum,val)=>sum + val,0)}</span>
                  }
                  </span>
                  </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div
        className='c-header__outer c-header__outer--desktop c-header__outer--header-type-1'
        style={{ minHheight: "91px" }}
      >
        <div
          className='c-header c-header--desktop js-header-desktop c-header--header-type-1 c-header--rows c-header--init'
          style={{ top: "0px" }}
        >
          <div className='c-header__row-top'>
            <div className='c-header__cell c-header__cell--top-left'>
              <div className='c-header__top-row-item c-header__top-row-item--social'>
                <div className='c-soc'>
                  <a href='/#' className='c-soc__link' target='_blank'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a href='/#' className='c-soc__link' target='_blank'>
                    <FontAwesomeIcon icon={faInstagramSquare} />
                  </a>
                  <a href='/#' className='c-soc__link' target='_blank'>
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href='/#' className='c-soc__link' target='_blank'>
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </div>
              </div>
            </div>
            <div className='c-header__cell c-header__cell--top-center'>
              <div className='c-header__logo c-header__logo--desktop '>
                <NavLink
                  href='/'
                  className='c-header__logo-link'
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  BANKA JEWELLERS
                </NavLink>
              </div>
            </div>
            <div className='c-header__cell c-header__cell--top-right'>
              <div className='c-header__buttons c-header__buttons--header-type-1'>
                <button
                  className='h-cb c-header__button-link c-header__search-button js-search-button'
                  type='button'
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className='h-hide-mobile'
                  />

                  <i className='ip-m-search h-hide-desktop'></i>
                </button>
                <NavLink
                  className='c-header__button-link c-header__button-link--account'
                  title='Login'
                  to='/login'
                >
                  <FontAwesomeIcon icon={faUser} className='h-hide-mobile' />
                </NavLink>
                <div className='c-header__wishlist'>
                  <FontAwesomeIcon icon={faHeart} className='h-hide-mobile' />
                </div>
                <div className='c-header__cart c-header__cart--default js-cart'>
                  <NavLink className='c-header__button-link ' to='/cart/'>
                    <FontAwesomeIcon
                      icon={faBagShopping}
                      className='h-hide-mobile'
                    />
                    <span className='js-cart-info'>
                    <span className="c-header__cart-count js-cart-count">{
                    cart !==undefined && cart.map(item=>item.quantity).reduce((sum,val)=>sum + val,0)
                      
                    }</span>
                    </span>
                  </NavLink>
                  <div className='widget_shopping_cart_content'>
                    <div className='c-product-list-widget__empty woocommerce-mini-cart__empty-message'>
                      No products in the cart.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='c-header__row-bottom'>
            <div className='c-header__cell c-header__cell--bottom-left'>
              <div className='c-header__top-row-item c-header__top-row-item--phone'>
                <FontAwesomeIcon
                  className='ip-phone c-header__top-row-icon c-header__top-row-icon--phone'
                  icon='fa fa-phone'
                />
                <a href='tel:+91 9437053975'>+91 9437053975</a>
              </div>
              <div className='c-header__top-row-item c-header__top-row-item--hours'>
                <FontAwesomeIcon
                  className='ip-phone c-header__top-row-icon c-header__top-row-icon--phone'
                  icon={faEnvelope}
                />
                info@bankajewellers
              </div>
            </div>
            <div className='c-header__cell c-header__cell--bottom-center'>
              <nav
                itemScope=''
                itemType='http://schema.org/SiteNavigationElement'
                className='c-top-menu js-top-menu'
              >
                <ul
                  id='top-menu-desktop'
                  className='c-top-menu__list c-top-menu__list--popup-fullwidth'
                >
                  <li className='c-top-menu__item menu-item-207 c-top-menu__item--has-children js-menu-item initialized'>
                    <NavLink to='/'>Home</NavLink>
                  </li>
                  <li className='c-top-menu__item c-top-menu__item--has-children menu-item-208 js-menu-item initialized'>
                    <NavLink to='/shop'>Shop</NavLink>
                  </li>
                  <li className='c-top-menu__item c-top-menu__item--has-children menu-item-208 js-menu-item initialized'>
                    <NavLink to='/about-us'>About Us</NavLink>
                  </li>
                  <li className='c-top-menu__item c-top-menu__item--has-children menu-item-208 js-menu-item initialized'>
                    <NavLink to='/contact-us'>Contact us</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='c-header__cell c-header__cell--bottom-right'>                        
              {user.token &&  ` Hi ${user.name},`}
              {user.token && <span><NavLink to='/user/'>My Profile</NavLink></span>}
              {user.token && <span onClick={handleLogout} style={{ cursor: "pointer" }}>logout</span>}
            </div>
          </div>
        </div>
      </div>
    </header>    
  );
};

export default MainHeader;
