import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { add } from "../store/cartSlice";
import { fetchProducts } from "../store/productSlice";
import { getProductCategory } from "../store/productCategorySlice";
//import {socketConnect} from "./socketConnect";


import { Link } from "react-router-dom";

import styles from "./shop.module.css";

const ProductsGrid = (props) => {
  const dispatch = useDispatch();
  
  const { data: productStore } = useSelector(
    (state) => state.productStore
  );
  const { data: categoryStore } = useSelector(
    (state) => state.categoryStore
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log(isLoading);

  // const result = useSelector((state) => state.product);
  // const [products, setProducts] = useState([]);
  // console.log('state.product',productStore)
  
  const products = [...productStore];

  //const socket = socketConnect();
  useEffect(() => {
    dispatch(getProductCategory());
  }, [dispatch]);
  useEffect(() => {
    if (!productStore.length) {
      dispatch(fetchProducts());
    }else {
      setIsLoading(false);
    }
  }, [dispatch,productStore,categoryStore]);
 // const prevProductStoreRef = useRef(productStore.data);
 /*
  useEffect(() => {
    socket.on('products:fetchSuccess', (res) => {
      console.log('product grid server emit received');
      console.log(res.products);    
     
        dispatch(fetchGoldPrice(res.products));        
      
    });
  },[]);
  */
/*
  const dispatchProducts = useCallback(
    (products) => dispatch(setProducts(products))
  );
  
  useEffect(() => {
    socket.on('products:fetchSuccess', (res) => {
      console.log('product grid server emit received');
      console.log(res.products);
      dispatchProducts(res.products);    
    });
  }, [dispatchProducts]);
  */
  
  
//const prevProductStoreRef = useRef(productStore.data);


  const handleSelected = (e, item) => {
    e.preventDefault();
    
    setSelectedOption(item);
  };

  
  useEffect(() => {
    
    if (!props.selectedFilters && !props.priceRange) {
      setFilteredProducts(products);
    } else {
      const newProductsSorted = products.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      let filtered = newProductsSorted;
      if (selectedOption) {
        filtered = filtered.filter((item) =>
          item.category.includes(selectedOption)
        );
      }
      if (props.priceRange) {
        filtered = filtered.filter(
          (item) =>
            item.price >= props.priceRange[0] &&
            item.price <= props.priceRange[1]
        );
      }
      setFilteredProducts(filtered);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedFilters, props.priceRange, selectedOption,productStore]);
  

  const addToCartHandler = (event, id) => {
    event.preventDefault();
    const product = productStore.filter((item) => item._id === id);
    console.log("product", product);
    dispatch(add(product[0]));
    toast.success("item added to cart");
  };

  //using productStore directly was thowing some error
  // on stackoverflow there was a a suggestion to make copy like below to avoide the error
  //https://stackoverflow.com/questions/64957735/typeerror-cannot-assign-to-read-only-property-0-of-object-object-array-in

  return (
    <div className='row'>
      <div className={`${styles.cPageHeader}`}>
      <div className={`${styles.filterwrap}`}>
      <div className={`${styles.subwrap}`}>
      <ul className={`${styles.filter}`}>
                {Array.isArray(categoryStore) && categoryStore.length > 0 && categoryStore.map((item) => {
                  
                  return (
                    <li key={item._id}
                      className={
                        item.categoryName === selectedOption
                          ? `${styles.categoryFilter} ${styles.selectedFilter}`
                          : styles.categoryFilter
                      }
                      
                    >
                    <span 
                    onClick={(e) => handleSelected(e, item.categoryName)}
                    className={item.categoryName === selectedOption 
                    ? `${styles.thumbWrap} ${styles.selectedFilter}`
                  :styles.thumbWrap}> 
                    <img src= {`/assets/products/images/${item.categoryImage?.filename}` }
                    alt={item.categoryImage?.filename}  />
                    </span>
                    </li>
                  );
                })}
                
              </ul></div></div></div>
      {filteredProducts.length !== 0 &&
        filteredProducts.map((product) => (
          <div key={product._id} className='col-lg-3 item'>
            <Link to={`/product-detail/${product.slug}`}>
              <img
                className='product-img'
                style={{ width: "100%", backgroundColor: "#f7f7f7" }}
                src={`${
                  product.images[0] !== undefined
                    ? "https://backend.bankajewellers.com/products/images/" +
                      product.images[0].filename
                    : ""
                }`}
                alt={product.name}
              />
              <div
                className='add-to-cart'
                onClick={(e) => addToCartHandler(e, product._id)}
              >
                Add to Cart
              </div>
            </Link>
            <div className='card-body'>
              <Link
                to={`/product/${product.category[0]}`}
                className='product-category'
              >
                {product.category[0]}
              </Link>
              <Link to={`/product/${product._id}`}>
                <h2 className='product-title'>{product.name}</h2>
              </Link>
              <Link to={`/product/${product._id}`}>
                <p className='product-desc'>{product.description}</p>
              </Link>

              <div className='row'>
                <div className='price'>&#8377;{product.price}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProductsGrid;
