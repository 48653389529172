import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddCategory from './admin/AddCategory'
import AddProduct from './admin/AddProduct'
import Dashboard from './admin/Dashboard'
import SideMenu from './admin/SideMenu'
import Products from './admin/Products';
import AdminRoute from './AdminRoute'
import SalesOrders from './admin/SalesOrders'
import UsersList from './admin/UsersList'
//import VideoUploadSocket from './admin/VideoUploadSocket'

const AdminLayout = () => {
  return (    
    <div>
    <div className='admin-topbar top-sticky'>
      <a className="navbar-brand" href="/"><h4>BANKA JEWELLERS</h4></a></div>
      
    <div className='row'>
    
    <div className='col-md-2 admin-menu sticky'>
    <SideMenu />
    </div>      
      <div className='col-md-10 admin-content'>
        <Routes>
        <Route element={<AdminRoute />}>         
          <Route path='/' element={<Dashboard />} />
          <Route path='/add-product' element={ <AddProduct />} />
          <Route path='/add-category' element={<AddCategory />} />
          <Route path='/products' element={<Products />} />
          <Route path='/orders' element={<SalesOrders />} />  
          <Route path='/users' element={<UsersList />} />
      {/*<Route path='/video-upload' element={<VideoUploadSocket />} />          */}
        </Route>
        </Routes>
      </div>
    </div>
  </div>
  )
}

export default AdminLayout