import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './home-carousel.css';

class HomeCarousel extends Component {
    render() {
        return (
            
            <Carousel showThumbs={false} preventMovementUntilSwipeScrollTolerance={true} and swipeScrollTolerance={50} >
                <div className='c-ip-slider c-ip-slider__item'>
                    <img src="assets/products/images/slide1.webp" className='c-ip-slider__image c-ip-slider__image--layout-1 c-ip-slider__image--mobile' alt='slide1' />
                    <div className="c-ip-slider__wrap c-ip-slider__wrap--layout-1 c-ip-slider__wrap--bottom"> 
                    <div className="c-ip-slider__title c-ip-slider__title--layout-1">
                        <span className="c-ip-slider__title-inner">Pearls From Heaven</span></div> 
                        <div className="c-ip-slider__subtitle c-ip-slider__subtitle--layout-1">
                            <span className="c-ip-slider__subtitle-inner">New brand collection</span></div> 
                            <a href="https://bankajewellers.com" 
                            className="c-button c-button--default c-ip-slider__button c-ip-slider__button--layout-1">Read more</a> </div>
                </div>
                <div className='c-ip-slider__item'>
                    <img src="assets/products/images/slide2.webp" className='c-ip-slider__image c-ip-slider__image--layout-1 c-ip-slider__image--mobile' alt='slide2' />
                    <div className="c-ip-slider__wrap c-ip-slider__wrap--layout-1 c-ip-slider__wrap--bottom"> 
                    <div className="c-ip-slider__title c-ip-slider__title--layout-1">
                        <span className="c-ip-slider__title-inner">Jadore Mystery</span></div> 
                        <div className="c-ip-slider__subtitle c-ip-slider__subtitle--layout-1">
                            <span className="c-ip-slider__subtitle-inner">New brand collection</span></div>
                             <a href="https://bankajewellers.com" 
                             className="c-button c-button--default c-ip-slider__button c-ip-slider__button--layout-1">Read more</a> </div>
                </div>
                <div className='c-ip-slider__item'>
                    <img src="assets/products/images/slide3.webp" className='c-ip-slider__image c-ip-slider__image--layout-1 c-ip-slider__image--mobile' alt='slide3' />
                    <div className="c-ip-slider__wrap c-ip-slider__wrap--layout-1 c-ip-slider__wrap--bottom"> 
                    <div className="c-ip-slider__title c-ip-slider__title--layout-1">
                        <span className="c-ip-slider__title-inner">Goldish Dreams</span></div> 
                        <div className="c-ip-slider__subtitle c-ip-slider__subtitle--layout-1">
                            <span className="c-ip-slider__subtitle-inner">New brand collection</span></div> 
                            <a href="https://bankajewellers.com" className="c-button c-button--default c-ip-slider__button c-ip-slider__button--layout-1">Read more</a> </div>
                </div>
            </Carousel>
            
        );
    }
};

export default HomeCarousel;