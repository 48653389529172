import React, { useState } from "react";
import { useDispatch } from "react-redux";
//import { useNavigate } from "react-router-dom";
import { patchUser } from "../../store/usersSlice";

const EditUser = (props) => {
  const dispatch = useDispatch();
 // const navigate = useNavigate();
  const [user, setUser] = useState(props.selectedUser);

  const submitHandler = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("id", user._id);
    formData.append("name", user.name);
    formData.append("password", user.password);
    formData.append("isAdmin", user.isAdmin);
    formData.append("isVerified", user.isVerified);
    try {
      dispatch(patchUser({user:user})).unwrap();
      setUser({});
      props.closeModal();


    } catch (err) {
      console.error("Failed to save the user", err);
    }
  };

  return (
    <div className='container'>
      <h3>EditUser</h3>
      <form className='form' onSubmit={submitHandler}>
        <div>
          <label htmlFor='name'>Name</label>
          <input
            type='text'
            id='name'
            className='form-control'
            placeholder='Enter Name'
            value={user.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          ></input>
        </div>

        <div>
          <label htmlFor='email'>email</label>
          <input
            type='text'
            id='email'
            className='form-control'
            placeholder='Enter Name'
            value={user.email}
            disabled={true}
          ></input>
        </div>

        <div>
          <label htmlFor='password'>Update Password</label>
          <input
            type='password'
            id='password'
            className='form-control'
            placeholder='update password'
            
            onChange={(e) => setUser({ ...user, newPassword: e.target.value })}
          ></input>
          <div id='passwordHelp' className='form-text'>
            Do not update or change password field if you don't want to change
            the password..
          </div>
        </div>
        <div>
          <input
            type='checkbox'
            id='role'
            className='form-check-input'
            placeholder=''
            checked={user.isAdmin}
            onChange={(e) => setUser({ ...user, isAdmin: e.target.value })}
          ></input>
          <label htmlFor='role'>&nbsp;is Admin</label>
        </div>
        <div>
          <input
            type='checkbox'
            id='verified'
            className='form-check-input'
            placeholder=''
            checked={user.isVerified}
            onChange={(e) => setUser({ ...user, isVerified: e.target.value })}
          ></input>
          <label htmlFor='role'>&nbsp;Email Verified</label>
        </div>
        <div>
          <label />
          <button
            className='c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3'
            type='submit'
          >
            Submit
          </button>
        </div>
        <div>
          <label />
        </div>
      </form>
    </div>
  );
};

export default EditUser;
