import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NewProducts from "./NewProducts";
import FeaturedProducts from "./FeaturedProducts";
import './tabbed.css';
import OnSaleProducts from "./OnSaleProducts";

const HomeTabbed = () => {
  return (
    <div>
      <h3 className="sectionHeading">Luxury Jewellery</h3>
      <div className="tabWrapper">
     <Tabs
      defaultActiveKey="new"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="new" title="NEW ARRIVED">
        <NewProducts />
      </Tab>
      <Tab eventKey="featured" title="FEATURED">
        <FeaturedProducts />
      </Tab>
      <Tab eventKey="onsale" title="ON SALE" >
        <OnSaleProducts />
      </Tab>
    </Tabs>
    </div>
    </div>
  );
};

export default HomeTabbed;
