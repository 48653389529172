import React from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { logout } from "../store/userSlice";
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';


import 'react-pro-sidebar/dist/css/styles.css';


const UserMenu = () => {
  const dispatch=useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <ProSidebar style={{minWidth:'auto',width:'auto'}}>
  <Menu iconShape="square">
  <MenuItem > My Profile  <Link to="/user/" /></MenuItem>

    <MenuItem>Orders History<Link to="/user/order-history" /></MenuItem>
    <MenuItem>Shipping Address<Link to="/user/address-list" /></MenuItem>
    <MenuItem onClick={handleLogout}>Logout<Link to="/" /></MenuItem>
  </Menu>
</ProSidebar>
  );
};

export default UserMenu;
