import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {    
    faPhone,    
    faLocation,
    faClockFour,
    faEnvelope,
    faCircle,
  } from "@fortawesome/free-solid-svg-icons";


const MainFooter = () => {
  return (
    <footer className='l-section c-footer '>
        <div className='row element-container'>
        <div className='col-lg-4 col-sm-12'>
        
            <div className='c-ip-heading__inner'>
                <p className='c-ip-heading'>
                MY ACCOUNT
                </p>
                <ul>
                
                    <li className='list-item'> 
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />                   
                        <span>My Account</span></li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Wishlist</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Cart</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Register</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Contact</li>
                    
                </ul>
            </div>
       
        </div>
        <div className='col-lg-4 col-sm-12'>
        
        <div className='c-ip-heading__inner'>
                <p className='c-ip-heading'>
                INFORMATION
                </p>
                <ul>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        About Us</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Our Blog</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        FAQ</li>                    
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Terms & Conditions</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faCircle}
                          className='c-footer__dot-icon'
                        />    
                        Refund & Return Policy</li>
                    
                </ul>
            </div>
            </div>
       
        <div className='col-lg-4 col-sm-12'>
        
        <div className='c-ip-heading__inner'>
        <p className='c-ip-heading'>
                OUR CONTACTS
                </p>
                <ul>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faLocation}
                          className='c-header__top-row-icon'
                        />
                        Banka Gali, Baidyanath Chowk, Sambalpur</li>
                    <li className='list-item'>
                    <FontAwesomeIcon
                          icon={faPhone}
                          className='c-header__top-row-icon'
                        />
                        +91 9437053975</li>
                    <li className='list-item'><FontAwesomeIcon
                          icon={faClockFour}
                          className='c-header__top-row-icon'
                        />
                        Mon - Sat:10 - 20
                        </li>                    
                    <li className='list-item'><FontAwesomeIcon
                          icon={faEnvelope}
                          className='c-header__top-row-icon'
                        />info@bankajewellers</li>
                    
                    
                </ul>
        </div>
        </div>
        </div>
        </footer>
  )
}

export default MainFooter