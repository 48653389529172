import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { loginUser } from '../store/userSlice';

export default function Login(props) {
  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';

  const userSignin = useSelector((state) => state.user);
  const { data } = userSignin;

  
  useEffect(() => {
    let redirectTo = redirect;
    if (data.token && data.isAdmin) {
      redirectTo = '/admin';
    } else if (!data.token) {
      redirectTo = '/login';
    }
    navigate(redirectTo);
  }, [navigate, data.token, data.isAdmin, redirect]);
  


  const dispatch = useDispatch();
   console.log('userSignin',data);
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(email,password);
    let formData = new FormData();
    formData.append('email',email);
    formData.append('password',password)
    try{
      dispatch(loginUser({email:email,password:password})).unwrap();     
      setEmail("")
      setPassword("");      
    }
    catch(err){
      console.log('failed to login user',err)
    }
    
  };
  
  
  return (
    <div className='container Row' style={{marginTop:'150px'}}>
      <form className="form" onSubmit={submitHandler}>
        <div className='text-center'>
          <h1>Sign In</h1>
        </div>
        
        
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className='form-control'
            id="email"
            value={email}
            placeholder="Enter email"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className='form-control'
            id="password"
            value={password}
            placeholder="Enter password"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label />
          <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3" type="submit">
            Sign In
          </button>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label />
          <div className='mb-3'>
            New customer?{' '}
            <Link to={`/register?redirect=${redirect}`}>
              Create your account
            </Link>
          </div>
          <div className='mb-3'>            
            <Link to={`/forget-password`}>
            Forget Password?
            </Link>
          </div>
          
        </div>
      </form>
    </div>
  );
}
