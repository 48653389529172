import React, { useState } from "react";
import axios from "axios";

const ForgetPassword = () => {
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("forget password");

    try {
      axios
        .post("https://backend.bankajewellers.com/api/user/forgetpassword", {
          email: email,
        })
        .then((response) => {
          if (response.data.emailExists) {
            setSuccess(true)
          } else {
            setError(true);
          }
        });
    } catch (err) {
      console.log("failed to login user", err);
    }
  };
  return (
    <div>
      <h3>ResetPassword</h3>
      return (
      <div className='container Row' style={{ marginTop: "150px" }}>
        <form className='form' onSubmit={submitHandler}>
          <div className='col-sm-12 col-lg-4 offset-4'>
            <label htmlFor='email'>Email address</label>
            <input
              type='email'
              className='form-control'
              id='email'
              placeholder='Enter email'
              required
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>

          <div className='col-sm-12 col-lg-4 offset-4'>
            <label />
            <button
              className='c-button c-button--full c-button--large c-button--accent button alt mt-3'
              type='submit'
            >
              Submit
            </button>
            {success && (
              <p style={{ color: "green" }}>
                An Email is sent to your registered address, for further instructions to reset your password.                
              </p>
            )}
            {error && (
              <p style={{ color: "red" }}>
                No user exists in our system with that email. you can register
                for a new account{" "}
                <a href='/register'>
                  <span>here</span>
                </a>
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
