import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');


export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});
const initialState={
    _id:null,
    name:'',
    email:'',
    isAdmin:false,
    token:null
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },
    reducers: {
        // setProducts(state, action) {
        //     state.data = action.payload;
        // },
        // setStatus(state, action) {
        //     state.status = action.payload;
        // },
        logout(state,action){
            state.data=initialState
        }
    },
    extraReducers: (builder) => {
        builder
        /*
            .addCase(addOneUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(addOneUser.fulfilled, (state, action) => {
                console.log(action.payload); 
                state.status = STATUSES.IDLE;               
                state.data = action.payload;
                                               
            })
            .addCase(addOneUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            */
            .addCase(updateUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                console.log(action.payload);
                state.status = STATUSES.IDLE;
                return state.data.push(action.payload);               
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(loginUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            
            
            
            
        
    },
});

export const { setUser, setStatus,logout } = userSlice.actions;
export default userSlice.reducer;

// Thunks
/*
export const addOneUser = createAsyncThunk('register/post', async (formData) => {
    const res = await axios.post('https://backend.bankajewellers.com/api/user/register',formData);
   console.log(res)
  //  const data =  res;
    //console.log(data.productCategory);
    return res.data;
});
*/
export const updateUser = createAsyncThunk('update/post', async (formData) => {
    const res = await axios.patch('https://backend.bankajewellers.com/api/user',formData);
   console.log(res)
   // const data =  res;
    //console.log(data.productCategory);
    return res;
});

export const loginUser = createAsyncThunk('login/post', async (formData) => {
    
    const res = await axios.post('https://backend.bankajewellers.com/api/user/login',formData);
   //console.log(res)
    const data =  res.data;
    //console.log(data.productCategory);
    return data;
});


// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
