import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import { updateProduct } from "../../store/productSlice";
import { videoUpload } from "./VideoUpload";

import toast from 'react-hot-toast';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./admin.scss";

const EditProduct = (props) => {
  
  const dispatch = useDispatch();
  const { data: categoryStore } = useSelector(
    (state) => state.categoryStore
  );
  
  const [ setProduct] = useState({});
  const [file, setFile] = useState([]);
  const [productName, setProductName] = useState(props.product.name);
  const [sku, setSku] = useState(props.product.sku);
  const [netWeight, setNetWeight] = useState(props.product.netWeight);
  const [grossWeight, setGrossWeight] = useState(props.product.grossWeight);
  const [purity, setPurity] = useState(props.product.purity);
  const [brand, setBrand] = useState(props.product.brand ||'');
  const [productDescription, setProductDescription] = useState(props.product.description ||'');
  const [sellingPrice, setSellingPrice] = useState(props.product.price);
  const [specialPrice, setSpecialPrice] = useState(props.product.onSalePrice ||'');
  const [makingRate, setMakingRate] = useState(props.product.makingRate ||'');
  const [featured, setFeatured] = useState(props.product.featured);
  //const [category, setCategory] = useState();
  const [video, setVideo] = useState(props.product.video);
  const [videoUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState(props.product.images);
  const [selectedOption, setSelectedOption] = useState(props.product.category.map((item) => {
    return { value: item, label: item };
  }));
 

  console.log('purity',purity);

  /*
  useEffect(() => {
    setProduct(props.product);
  }, [props.product])
  */
  useEffect(() => {
    if (images.length === 0) {
    setImages(props.product.images);
    }
  }, [images.length,props.product.images])

  const handleVideoUploadResponse = (res) => {
    toast.success(res.message); ;
    setVideo("");
    setVideoUrl('');
    };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

    const handleImage = useCallback(async (event) => {
      try {
        const images = event.target.files;
        Array.from(images).forEach(async (img) => {
          const result = await resizeFile(img);
         
          setFile((prevState) => {
            const newState = [...prevState];
            newState.push(result);
            return newState;
          });
        });
        
        //return 'handleOk';
      } catch (err) {
        console.log(err);
      }
    },[]);
  
    const handleVideo = (e) => {
      const file = e.target.files[0];
      console.log("videofile", file);
      const url = URL.createObjectURL(file);
      setVideoUrl(url);
      setVideo(file);
    };
  const submitHandler = async (event) => {
    event.preventDefault();
    
    //props.setEditMode(false);

    // console.log("selected option", selectedOption);
  

    // let result = await handleImage();
    //if (file.length > 0) {
      const imgFiles =Array.from(file).map((item)=>{
        return {file:item,fileName:item.name}
      });
      console.log('props product id',props.product?._id)
      let formData={
        id:props.product._id,
        productName:productName,
        sku:sku,
        brand:brand,
        productDescription:productDescription,
        sellingPrice:sellingPrice,
        netWeight:Number(netWeight),
        grossWeight:Number(grossWeight),
        makingRate:makingRate,
        purity:purity,
        productCategory:selectedOption,
        featured:featured,
        specialPrice:isNaN?null:specialPrice,
        imgFiles:imgFiles 
      }
      /*
    let formData = new FormData();
    formData.append("id", props.product._id);
    formData.append("productName", productName);
    formData.append("netWeight", Number(netWeight));
    formData.append("grossWeight", Number(grossWeight));
    formData.append("purity", purity);
    formData.append("sku", sku);
    formData.append("brand", brand);
    formData.append("productDescription", productDescription);
    formData.append("sellingPrice",sellingPrice);
    formData.append("makingRate", makingRate);
    formData.append("featured",featured);
    formData.append("specialPrice", specialPrice==NaN?null:specialPrice);

    //const categoryValues = product.category.map(category => category);
    // formData.append("productCategory", ...product.category);

    Array.from(selectedOption).forEach((item) => {
      formData.append("category", item.value);
    });

    Array.from(file).forEach((item) => {
      formData.append("products", item);
    });
    console.log('selectedOption',selectedOption);
    */
    try {
      dispatch(updateProduct(formData)).then((res) => {
        const id=res.payload._id;
        console.log(res);
        setFile("");
        setProductName("");
        setMakingRate("");
        setSku("");
        setBrand("");
        setProductDescription("");
        setSellingPrice("");
        setSelectedOption("");
        setFile("");
        if(video?.name){
          videoUpload({onResponse:handleVideoUploadResponse, _id:id, video:video})
          }
        console.log('after inclusion of video upload component');
        
        
      })  ;

      setProduct({});

      setSelectedOption("");
      props.closeModal()

      
    } catch (err) {
      console.error("Failed to save the category", err);
    }
  };

  
  var options = [];
  categoryStore.forEach((item) => {
    options.push({ value: item.categoryName, label: item.categoryName });
  });

  return (
    <div>
      <h1>Update Products</h1>
      <form onSubmit={submitHandler}>
        <div className='row'>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Product Name:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Product name'
              name='productName'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Product SKU:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Product SKU'
              name='sku'
              value={sku}
              readOnly
              onChange={(e) => setSku(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Nett Weight:</label>
            <input
              className='form-control'
              type='number'
              step="any"
              placeholder='Nett Weight'
              name='weight'
              value={netWeight}
              onChange={(e) => setNetWeight(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Gross Weight:</label>
            <input
              className='form-control'
              type='number'
              step="any"
              placeholder='Gross Weight'
              name='weight'
              value={grossWeight}
              onChange={(e) => setGrossWeight(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <div className="purity" >Purity</div>
            <input
              //key={purity}              
              className='form-check-input'
              type='radio'             
              name='916'
              id={916}
              value={purity}
              checked={purity === 916}              
              onChange={(e) => setPurity(916)}              
            />
            <label className='form-label' htmlFor='916' style={{marginRight:"10px"}}>916</label>
            <input
              className='form-check-input'
              type='radio'
              name='833'
              id='833'
              value={purity}
              checked={purity === 833}
              onChange={(e) => setPurity(833)}
            />
            <label className='form-label' htmlFor='833' style={{ marginRight: "10px" }}>
              833
            </label>
            <input
              className='form-check-input'
              type='radio'
              name='750'
              id='750'
              value={purity}
              checked={purity === 750}
              onChange={(e) => setPurity(750)}
            />
            <label className='form-label' htmlFor='750'>
              750
            </label>
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Brand:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Brand'
              name='brand'
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Product Description:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Product Desc'
              name='productDescription'
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Price:</label>
            <input
              className='form-control'
              type='number'
              placeholder='Price'
              name='sellingPrice'
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Special Price:</label>
            <input
              className='form-control'
              type='number'
              placeholder='Special Price'
              name='Special Price'
              value={specialPrice}
              onChange={(e) => setSpecialPrice(e.target.value)}
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Making Rate:</label>
            <input
              className='form-control'
              type='number'
              placeholder='Making Rate'
              name='makingRate'
              value={makingRate}
              onChange={(e) => setMakingRate(e.target.value)}
              required
            />
          </div>
          

          <div className='col-md-12 col-lg-4'>
            <label className='form-label'>Category:</label>
            <Select
              isMulti
              name='category'
              options={options}
              // defaultValue={selectedOption}
              // inputValue={selectedOption}
              /*
              value={
                category &&
               category.map((item) => {
                  return { value: item, label: item };
                })
              }*/
              value={selectedOption}
             // onChange={(e) =>setSelectedOption( e.map((option) => option.value))}
             onChange={setSelectedOption}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </div>
          <div
            className='col-md-12 col-lg-4 mt-3 ms-3 me-n6 form-check'
            style={{ marginRight: "-16px" }}
          >
            <input
              className=' form-check-input'
              type='checkbox'
              name='featured'
              checked={featured}
              onChange={(e) => setFeatured(e.target.value)}
              
            />
            <label className='form-check-label'>Featured</label>
          </div>
          <div className='col-12 col-lg-8 mb-3 mt-3'>
            <p>Existing Images</p>

            {images &&
              Array.from(images).map((item) => {
               // console.log("images item", item);
                return (
                  <span key={Math.random() * 100}>
                    <img
                      style={{ padding: "10px" }}
                      width={150}
                      src={
                        item
                          ? `https://backend.bankajewellers.com/products/images/mobile-${item.filename}`
                          : null
                      }
                      alt=''
                    />
                  </span>
                );
              })}
          </div>
          <div className='col-12 col-lg-12 mb-3 mt-3'>
            {Array.from(file).map((item) => {
              return (
                <span key={Math.random(10, 100)}>
                  <img
                    style={{ padding: "10px" }}
                    width={150}
                    src={item ? URL.createObjectURL(item) : null}
                    alt=''
                  />
                </span>
              );
            })}
          </div>
          <div className='row'>
            <h3>Upload your catalogue</h3>
            <div className='form-group'>
              <input
                type='file'
                name='photos'
                disabled={file.length === 5}
                className='form-control'
                onChange={handleImage}
                multiple
              />
            </div>
          </div>
          <div className='col-12 col-lg-12 mb-3 mt-3'>
            <span>{videoUrl && <video width="640" src={videoUrl} controls />}</span>
          </div>
          <div className='row'>
          <h3>Upload a video</h3>
            <div className='form-group'>
              <input
                type='file'
                name='video'
                className='form-control'
                onChange={handleVideo}
              />
            </div>
          </div>
        </div>
        <Button type='submit' className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3">Submit</Button>
      </form>
    </div>
  );
};

export default EditProduct;
