import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProfile } from '../store/userProfileSlice';


const AddShippingAddress = (props) => {
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('India');
  const [phone, setPhone] = useState('');

  const userRegister = useSelector((state) => state.user);
  const { data } = userRegister;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
      formData.append("_id",data._id);
      formData.append("fullName",fullName);
      formData.append("address",address);
      formData.append("city",city);
      formData.append("postalCode",postalCode);
      formData.append("country",country);
      formData.append("phone",phone);
      try{
        dispatch(addProfile(formData)).unwrap();
        props.closeModal();
      }
      catch(err){
        console.error("Failed to save the category", err);

      }
        

  };
  return (
    
    <div className='container shipping-container'>
    <form className="form" onSubmit={submitHandler}>
    <div>
      <h1>Add Shipping</h1>
    </div>
    
    
    <div>
      <label htmlFor="fullName">Full Name</label>
      <input
        type="text"
        id="fullName"
        className='form-control'
        placeholder="Enter Full Name"
        value={fullName}
        required
        onChange={(e) => setFullName(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="address">Address</label>
      <input
        type="text"
        id="address"
        className='form-control'
        placeholder="Enter Address"
        required
        onChange={(e) => setAddress(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="city">City</label>
      <input
        type="text"
        id="city"
        className='form-control'
        placeholder="Enter City"
        required
        onChange={(e) => setCity(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="postalcode">Postal Code</label>
      <input
        type="text"
        id="postalcode"
        className='form-control'
        placeholder="Enter Postal Code"
        required
        onChange={(e) => setPostalCode(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="country">Country</label>
      <input
        type="text"
        id="country"
        className='form-control'
        placeholder="Enter Country"
        required
        onChange={(e) => setCountry(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="phone">Contact Phone</label>
      <input
        type="text"
        id="phone"
        className='form-control'
        placeholder="Enter Phone"
        required
        onChange={(e) => setPhone(e.target.value)}
      ></input>
    </div>
    <div>
      <label />
      <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3" type="submit">
        Submit
      </button>
    </div>
    <div>
      <label />
      
    </div>
  </form>

  </div>

  )
}

export default AddShippingAddress