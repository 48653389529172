import React,{useEffect} from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { socketConnect } from "./components/socketConnect";
import AdminLayout from "./components/AdminLayout";
import CustomerLayout from "./components/CustomerLayout";

import UserLayout from "./components/UserLayout";
import { fetchGoldPrice } from "./store/productSlice";



function App() {
  const dispatch = useDispatch();
  const socket = socketConnect();
  
  
  useEffect(() => {
    socket.on('products:fetchSuccess', (res) => {
      console.log('product grid server emit received');
      console.log(res.products);     
        dispatch(fetchGoldPrice(res.products));      
    });
  });
  return (
    <div className='App'>      
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<CustomerLayout />} />
          <Route path='/admin/*' element={<AdminLayout />} />
          <Route path='/user/*' element={<UserLayout />} />
          
                   
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
