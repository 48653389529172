import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from '../store/userProfileSlice';

import { Modal } from 'react-bootstrap';
import AddShippingAddress from './AddShippingAddress';



const ShippingAddressesDashboard = () => {
    const dispatch = useDispatch();    
    const { data: profile } = useSelector((state) => state.profile);
    const { data: user } = useSelector((state) => state.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    useEffect(() => {
        dispatch(getProfile(user._id));
      }, [dispatch,user._id]);
    
    const addAddress=(e)=>{
      e.preventDefault(); 
      setIsModalOpen(true);
    }   
    

  return (
    <div className='container row'>
      <div className='col-4 card p-3 mb-3' onClick={addAddress}
        
        style={{cursor:'pointer',        
        backgroundColor:'#333',color:'#fff',        
        textAlign:'center',        
        }}>
        <h3 style={{paddingTop:'50px',verticalAlign:'middle'}}>Add Address</h3>              
      </div>
      {isModalOpen && <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <AddShippingAddress closeModal={() => setIsModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
     
               
          {profile?.shipping !== undefined && profile.shipping.map(el=>(             
             <div className='col-4'>
              <div className='card p-3 mb-3'>
            <div>{el.fullName}</div>
            <div>{el.address}</div>
            <div>{el.city}</div>
            <div>{el.postalCode}</div>
            <div>{el.country}</div>
            <div>{el.phone}</div>            
          
          </div></div>
          )
         
         )}
         
         
       
       
        </div>
  )
}

export default ShippingAddressesDashboard
