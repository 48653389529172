import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProfile, getProfile } from '../store/userProfileSlice';
import AddressList from './AddressList';





const Shipping = () => {
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('India');
  const [phone, setPhone] = useState('');

  const userRegister = useSelector((state) => state.user);
  const {data:profile} = useSelector((state) => state.profile)
  const { data } = userRegister;

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getProfile(data._id));
  },[dispatch,data._id]);
  console.log(profile);

 
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
      formData.append("_id",data._id);
      formData.append("fullName",fullName);
      formData.append("address",address);
      formData.append("city",city);
      formData.append("postalCode",postalCode);
      formData.append("country",country);
      formData.append("phone",phone);
      try{
        dispatch(addProfile(formData)).unwrap(); 
      }
      catch(err){
        console.error("Failed to save the category", err);

      }
        

  };
  return (
    
    <div className='container shipping-container'>
     {profile && profile?.shipping !== null && <AddressList />}
      {profile === null && <form className="form" onSubmit={submitHandler}>
    <div className='col-12 col-lg-4 offset-4'>
      <h1>Shipping Detail</h1>
    </div>
    
    
    <div className='col-12 col-lg-4 offset-4'>
      <label htmlFor="fullName">Full Name</label>
      <input
        type="text"
        id="fullName"
        className='form-control'
        placeholder="Enter Full Name"
        value={fullName}
        required
        onChange={(e) => setFullName(e.target.value)}
      ></input>
    </div>
    <div className='col-12 col-lg-4 offset-4'>
      <label htmlFor="address">Address</label>
      <input
        type="text"
        id="address"
        className='form-control'
        placeholder="Enter Address"
        required
        onChange={(e) => setAddress(e.target.value)}
      ></input>
    </div>
    <div className='col-12 col-lg-4 offset-4'>
      <label htmlFor="city">City</label>
      <input
        type="text"
        id="city"
        className='form-control'
        placeholder="Enter City"
        required
        onChange={(e) => setCity(e.target.value)}
      ></input>
    </div>
    <div className='col-12 col-lg-4 offset-4'>
      <label htmlFor="postalcode">Postal Code</label>
      <input
        type="text"
        id="postalcode"
        className='form-control'
        placeholder="Enter Postal Code"
        required
        onChange={(e) => setPostalCode(e.target.value)}
      ></input>
    </div>
    <div className='col-12 col-lg-4 offset-4'>
      <label htmlFor="country">Country</label>
      <input
        type="text"
        id="country"
        className='form-control'
        placeholder="Enter Country"
        required
        onChange={(e) => setCountry(e.target.value)}
      ></input>
    </div>
    <div className='col-12 col-lg-4 offset-4'>
      <label htmlFor="phone">Contact Phone</label>
      <input
        type="text"
        id="phone"
        className='form-control'
        placeholder="Enter Phone"
        required
        onChange={(e) => setPhone(e.target.value)}
      ></input>
    </div>
    <div className='col-12 col-lg-4 offset-4'>
      <label />
      <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3" type="submit">
        Proceed to Payment
      </button>
    </div>
    <div>
      <label />
      
    </div>
  </form>
}
  </div>

  )
}

export default Shipping