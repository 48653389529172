import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clean } from '../store/cartSlice';

const CheckoutSuccess = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(clean());
  });
  

  return (
    <div className='container'><h2>CheckoutSuccess</h2></div>
  )
}

export default CheckoutSuccess