import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');


export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});
const initialState={
    _id:null,
    name:'',
    email:'',
    isAdmin:false,
    
};

const registerSlice = createSlice({
    name: 'register',
    initialState: {
        data: initialState,
        status: STATUSES.IDLE,
    },
    reducers: {
        // setProducts(state, action) {
        //     state.data = action.payload;
        // },
        // setStatus(state, action) {
        //     state.status = action.payload;
        // },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                console.log(action.payload.message); 
                state.status = STATUSES.IDLE;               
                state.data = action.payload;
                                               
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
        
    },
});

export const { setRegister, setStatus,logout } = registerSlice.actions;
export default registerSlice.reducer;

// Thunks
export const registerUser = createAsyncThunk('register/post', async (formData) => {
    const res = await axios.post('https://backend.bankajewellers.com/api/user/register',formData);
   //console.log(res)
  //  const data =  res;
    //console.log(data.productCategory);
    return res.data;
});




// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
