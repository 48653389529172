import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});
const initialState=[{
    _id:null,
    name:'',
    email:'',
    isAdmin:false,
    token:''
}];

const usersSlice = createSlice({
    name: 'users',
    initialState:{
        data:initialState,
        status:STATUSES.IDLE
    },
    reducers: {
        // setProducts(state, action) {
        //     state.data = action.payload;
        // },
        // setStatus(state, action) {
        //     state.status = action.payload;
        // },
       
    },
    extraReducers: (builder) => {
        builder
            .addCase(addUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                console.log(action.payload);
                state.status = STATUSES.IDLE;
                return state.data.push(action.payload);               
            })
            .addCase(addUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(patchUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            /*
            .addCase(patchUser.fulfilled, (state, action) => {
                console.log(action.payload);

                const updatedUser = action.payload.result;
                return {...state, data: {...state.data, [updatedUser._id]: updatedUser}};
            })
            */
            .addCase(patchUser.fulfilled, (state, action) => {
            const existingItem = state.data.findIndex((item) => item._id === action.payload.result._id);                     
            if(existingItem >=0){                  
                state.data[existingItem]=action.payload.result
            }            
        })
            .addCase(patchUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(getUsers.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                console.log(action.payload);
                state.data =action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            
            
            
            
        
    },
});

export const { setUsers, setStatus,logout } = usersSlice.actions;
export default usersSlice.reducer;

// Thunks
export const getUsers = createAsyncThunk('users/get', async () => {
    const res = await axios.get('https://backend.bankajewellers.com/api/user');
   console.log('users log',res)
   
    //console.log(data.productCategory);
    return res.data;
});

export const addUser = createAsyncThunk('register/user', async (formData) => {
    const res = await axios.post('https://backend.bankajewellers.com/api/user/register',formData);
   //console.log(res)
    const data =  res;
    //console.log(data.productCategory);
    return data;
});

export const patchUser = createAsyncThunk('patch/post', async (formData) => {
    const res = await axios.patch('https://backend.bankajewellers.com/api/user',formData);
   console.log(res)
   // const data =  res;
    //console.log(data.productCategory);
    return res.data;
});




// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
