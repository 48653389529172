import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";

const ResetPassword = (props) => {
  const { email,otp } = useParams();
    const [password,setPassword] = useState('')
    
    const [resetSuccess,setResetSuccess]=useState(false)
    const [error, setError] = useState(false);  

    const submitHandler = (e) => {
        e.preventDefault();
        
        try{          
           axios.post('https://backend.bankajewellers.com/api/user/resetpassword',
           ({email:email,password:password,otp:otp})).then(response => {
            if(response.data.reset) {
              setResetSuccess(true)
            } else {
             setError(true)
            }
          });
        
        }
        catch(err){
          console.log('failed to login user',err)
        }
        
      };
  return (    
    <div className='container Row' style={{marginTop:'150px'}}>

        <h3>Reset Password</h3>
      <form className="form" onSubmit={submitHandler}>
       
        
        
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="email">New Password</label>
          <input
            type="password"
            className='form-control'
            id="email"
           
            placeholder="Enter email"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label />
          <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3" type="submit">
            Submit
          </button>
        </div>
        
      </form>
      <div>
       {resetSuccess && <p>Your password is reset successfully. click <a href='/login'>here</a> to login</p>} 
      {error && <p>There was an Error resetting your password.
                Contact Adminstrator
              </p>}
      </div>
    </div>
    
  )
}

export default ResetPassword