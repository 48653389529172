import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import { Button } from "react-bootstrap";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import { addProduct, fetchProducts } from "../../store/productSlice";
import { getProductCategory } from "../../store/productCategorySlice";
import { videoUpload } from "./VideoUpload";


import toast from 'react-hot-toast';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./admin.scss";


const AddProduct = () => {
  const dispatch = useDispatch();
  
  const [file, setFile] = useState([]);
  const [video, setVideo] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [productName, setProductName] = useState("abc");
  const [sku, setSku] = useState("sdd");
  const [netWeight, setNetWeight] = useState(12.5);
  const [grossWeight, setGrossWeight] = useState(18.5);
  const [purity, setPurity] = useState(916);
  const [brand, setBrand] = useState("tiffany");
  const [productDescription, setProductDescription] = useState("");
  const [sellingPrice, setSellingPrice] = useState(125000);
  //const [specialPrice, setSpecialPrice] = useState("");
  const [makingRate, setMakingRate] = useState(350);
  //const [category, setCategory] = useState();
  const [selectedOption, setSelectedOption] = useState("necklaces");
  //const [editMode, setEditMode] = useState(false);
  const { data: categoryStore } = useSelector(
    (state) => state.categoryStore
  );
  const { data: productStore } = useSelector((state) => state.productStore);

  useEffect(() => {
    dispatch(getProductCategory());
  },[dispatch,categoryStore.data]);
  useEffect(() => {
    dispatch(fetchProducts());
  },[dispatch,productStore.data]);

  const handleVideoUploadResponse = (res) => {
    toast.success(res.message); ;
    setVideo("");
    setVideoUrl('');
    };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  //  const socket = socketConnect();
  const handleImage = async (event) => {
    try {
      const images = event.target.files;
      Array.from(images).forEach(async (img) => {
        const result = await resizeFile(img);
        console.log(result);
        setFile((prevState) => {
          const newState = [...prevState];
          newState.push(result);
          return newState;
        });
      });
      
      //return 'handleOk';
    } catch (err) {
      console.log(err);
    }
  };
  console.log('file after handleImage',file);
  
  

  const handleVideo = (e) => {
    const file = e.target.files[0];
    console.log("videofile", file);
    const url = URL.createObjectURL(file);
    setVideoUrl(url);
    setVideo(file);
  };
  const submitHandler = async (event, _id = null) => {
    event.preventDefault();
    
    const imgFiles =Array.from(file).map((item)=>{
      return {file:item,fileName:item.name}
    });
    let formData={
      productName:productName,
      sku:sku,
      brand:brand,
      productDescription:productDescription,
      sellingPrice:sellingPrice,
      netWeight:netWeight,
      grossWeight:grossWeight,
      makingRate:makingRate,
      purity:purity,
      productCategory:selectedOption,
      imgFiles:imgFiles    
      

    }
    /*
  let formData = new FormData();
formData.append("productName", productName);
formData.append("sku", sku);
formData.append("brand", brand);
formData.append("productDescription", productDescription);
formData.append("sellingPrice", sellingPrice);
formData.append("weight", weight);
formData.append("makingRate", makingRate);
formData.append("purity", purity);
Array.from(selectedOption).forEach((item) => {
  formData.append("productCategory", item.value);
});
Array.from(file).forEach((item) => {
  formData.append("products", item);
});
console.log('selected Option',selectedOption);
*/
try {
  dispatch(addProduct(formData))
    .then((res) => {
      const id=res.payload._id;
      console.log(res);
      setFile("");
      setProductName("");
      setMakingRate("");
      setSku("");
      setBrand("");
      setProductDescription("");
      setSellingPrice("");
      setSelectedOption("");
      setFile("");
      if(video.name){
        videoUpload({onResponse:handleVideoUploadResponse, _id:id, video:video})
        }
      console.log('after inclusion of video upload component');
      
      
    })  
} catch (err) {
  console.error("Failed to save the category", err);
};
  };


  var options = [];
  categoryStore.forEach((item) => {
    options.push({ value: item.categoryName, label: item.categoryName });
  });

  return (
    <div>
      <h1>Add Products</h1>
      <form onSubmit={submitHandler}>
        <div className='row'>
        <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Category:</label>
            <Select
              isMulti
              name='category'
              options={options}
              // defaultValue={selectedOption}
              // inputValue={selectedOption}
              value={selectedOption}
              onChange={setSelectedOption}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </div>
          
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Product Name:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Product name'
              name='productName'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Product SKU:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Product SKU'
              name='sku'
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Nett Weight:</label>
            <input
              className='form-control'
              type='number'
              step="any"
              placeholder='Nett Weight'
              name='netWeight'
              value={netWeight}
              onChange={(e) => setNetWeight(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Gross Weight:</label>
            <input
              className='form-control'
              type='number'
              step="any"
              placeholder='Gross Weight'
              name='grossweight'
              value={grossWeight}
              onChange={(e) => setGrossWeight(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Making Rate:</label>
            <input
              className='form-control'
              type='number'
              placeholder='Making Rate'
              name='makingRate'
              value={makingRate}
              onChange={(e) => setMakingRate(e.target.value)}
              required
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <div className='purity'>Purity</div>
            <input
              className='form-check-input'
              type='radio'
              name='916'
              id='916'
              value={purity}
              checked={purity === 916}
              onChange={(e) => setPurity(916)}
            />
            <label
              className='form-label'
              htmlFor='916'
              style={{ marginRight: "10px" }}
            >
              916
            </label>
            <input
              className='form-check-input'
              type='radio'
              name='833'
              id='833'
              value={purity}
              checked={purity === 833}
              onChange={(e) => setPurity(833)}
            />
            <label className='form-label' htmlFor='833' style={{ marginRight: "10px" }}>
              833
            </label>
            <input
              className='form-check-input'
              type='radio'
              name='750'
              id='750'
              value={purity}
              checked={purity === 750}
              onChange={(e) => setPurity(750)}
            />
            <label className='form-label' htmlFor='750'>
              750
            </label>
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Brand:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Brand'
              name='brand'
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Product Description:</label>
            <input
              className='form-control'
              type='text'
              placeholder='Product Desc'
              name='productDescription'
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <label className='form-label'>Price:</label>
            <input
              className='form-control'
              type='number'
              placeholder='Price'
              name='sellingPrice'
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
            />
          </div>
          
          <div className='col-12 col-lg-12 mb-3 mt-3'>
            {Array.from(file).map((item) => {
             // console.log(item);
              return (
                <span key={Math.random() * 100}>
                  <img
                    style={{ padding: "10px" }}
                    width={150}
                    src={item ? URL.createObjectURL(item) : null}
                    alt=''
                  />
                </span>
              );
            })}
          </div>
          <div className='row'>
            <h3>Upload your catalogue</h3>
            <div className='form-group'>
              <input
                type='file'
                name='photos'
                disabled={file.length === 5}
                className='form-control'
                onChange={handleImage}
                multiple
              />
            </div>
          </div>
          <div className='col-12 col-lg-12 mb-3 mt-3'>
          <h3>Upload a video</h3>
            <span>{videoUrl && <video width="640" src={videoUrl} controls />}</span>
          </div>
          <div className='row'>
            <div className='form-group'>
              <input
                type='file'
                name='video'
                className='form-control'
                onChange={handleVideo}
              />
            </div>
          </div>
        </div>
        <Button
          type='submit'
          className='c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3'
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddProduct;
