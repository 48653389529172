import React, { useState } from 'react';
import { Range,getTrackBackground } from 'react-range';

const PriceFilter = ({minPrice, maxPrice, onPriceChange}) => {
    const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);
  
    const handlePriceChange = (priceRange) => {
      setPriceRange(priceRange);
      onPriceChange(priceRange);
    }

    return (
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop:'30px',
        border:'1px solid silver'
      }}
    >
      <Range
      values={priceRange}
      step={1000}
      min={500}
      max={200000}
      onChange={handlePriceChange}
      renderTrack={({ props, children }) => (
        <div
         
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '90%', 
            marginTop:'30px'           
            
          }}
        >
          <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: priceRange,
                  colors: ['#ccc', '#548BF4', '#ccc'],
                  min: minPrice,
                  max: maxPrice
                }),
              alignSelf: 'center'
              }}
          >
          {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '42px',
            width: '42px',
            borderRadius: '4px',
            backgroundColor:  '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA'
          }}
        >
          <div
            style={{
              height: '16px',
              width: '5px',
              backgroundColor: isDragged ? '#548BF4' : '#ccc'
            }}
          />
        </div>
      )}
      />
      <output style={{ marginTop: '30px' }} id="output">
        {priceRange[0].toFixed(1)} - {priceRange[1].toFixed(1)}
      </output>
      </div>
  );
}

export default PriceFilter;
