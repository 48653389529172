import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { add } from "../store/cartSlice";
import { Carousel } from "react-responsive-carousel";
import "./product-detail.css";
import { useState } from "react";

const ProductGallery = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);

  const { data: productStore } = useSelector(
    (state) => state.productStore
  );
  const product = productStore.find((item) => item.slug === params.slug);
  //const product = result[0];

  const addqty = (e) => {
    e.preventDefault();

    setQuantity(quantity + 1);
  };
  const deductqty = () => {
    setQuantity(quantity - 1);
  };
  const addtoCart = () => {
    console.log("addtocart", product);
    const product1 = { ...product, quantity: quantity };
    console.log("addtocart-after quantity push", product);
    dispatch(add(product1));
    toast.success("item added to cart");
  };
  /*
  const customRenderThumb = (children) => {
    return children.map((item, index) => {
      if (index === 0) {
        return item.map((el, i) => {
          return (            
              <img src={`https://backend.bankajewellers.com/${product.images[i].filename}`} />           
          );
        });
      } else {
        return (          
            <img src={`/assets/products/images/video_placeholder.jpg`} />          
        );
      }
    });
  };
  */

  const customRenderThumb = (children) => {
    return children.map((item, i) => {
      console.log('renderthumb item',item)      
      if (item.type !== "video") {
        return (
          <img
            src={`https://backend.bankajewellers.com/products/images/${product.images[i].filename}`}
            alt={product.images[i].filename}
          />
        );
      } else {
        return (
          <img
            src={`/assets/products/video/video_placeholder.jpg` } alt='video_placeholder'
            style={{ position: "relative", float: "left", width: "80px" }}
          />
        );
      }
    });
  };
  
  let mergedArray = [];
if (product?.video) {
  mergedArray = [...product.images, product.video];
} else {
  mergedArray = product.images;
}

  console.log('mergedArray',mergedArray)

  return (
    <section>
      <div className='row container'>
        <div
          className='col-lg-6 col-sm-12'
          style={{ border: "silver 1px solid" }}
        >
          <Carousel showThumbs={true} renderThumbs={customRenderThumb}>
            {mergedArray &&
              mergedArray.map((element) => {                
                if (
                  element.originalname &&
                  element.originalname.includes("webp")
                ) {
                  return (
                    <img
                      className='imagesthumb'
                      key={element.originalname}
                      src={`https://backend.bankajewellers.com/products/images/${element.filename}`}
                      alt={element.type}
                      style={{ marginBottom: "10px" }}
                    />
                  );
                } else {
                  return (
                    <video
                      width='640'
                      key={element.originalname}
                      className='videothumb'
                      src={`https://backend.bankajewellers.com/products/video/${element.originalname}`}
                      controls
                      alt='video'
                    />
                  );
                }
              })}
          </Carousel>
        </div>
        <div className='col-lg-6 col-sm-12'>
        <h2 className='c-product__title'>{product.name}</h2>
          <table className='table' style={{ border: "1px solid silver" }}>
            <tr>
              <td>Net Weight</td>
              <td> {Number(product.netWeight).toFixed(3)}gm</td>
            </tr>
            <tr>
              <td>Gross Weight</td>
              <td> {Number(product.grossWeight).toFixed(3)}gm</td>
            </tr>

            <tr>
              <td>Purity</td>
              <td> Hallmark {product.purity}</td>
            </tr>
            <tr>
              <td>Brand</td>
              <td>{product.brand}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td> {product.category.join(", ")}</td>
            </tr>
          </table>
          <p className='c-product__short-description'>{product.description}</p>
          <div className='c-product__quantity quantity'>
            <div className='reduce-qty' onClick={deductqty}>
              -
            </div>
            <div className='qty'>
              <input
                type='text'
                className='form-control quantity'
                value={quantity}
              />
            </div>
            <div className='addqty' onClick={addqty}>
              +
            </div>
          </div>
          <button className='btn btn-add mb-3' onClick={addtoCart}>
            ADD TO CART
          </button>
          
          
          <div className='price'>
            &#8377;{product.price.toFixed(2)}
            <div style={{ fontSize: "small" }}>Inclusive of Taxes</div>
          </div>

          <h5>Price Bifurcation</h5>
          <div style={{ display: "flex" }}>
            <div style={{ width: "150px" }}>Gold Price :</div>
            <div style={{ width: "150px", float: "left" }}>
              {product.basePrice.toFixed(2)}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "150px" }}>Making Charge :</div>
            <div style={{ width: "150px", float: "left" }}>
              {product.makingCharge.toFixed(2)}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "150px" }}>Taxes(GST) :</div>
            <div style={{ width: "150px", float: "left" }}>
              {product.gstAmount?.toFixed(2)}
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default ProductGallery;
