
import { configureStore,combineReducers } from '@reduxjs/toolkit';
//import counterReducer from '../features/counter/counterSlice';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import cartReducer from './cartSlice';
import productCategoryReducer from './productCategorySlice';
import productReducer from './productSlice';
import userReducer from './userSlice';
import usersReducer from './usersSlice';
import profileReducer from './userProfileSlice';
import salesOrderReducer from './salesOrderSlice';
import registerSliceReducer from './registerSlice';


const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
    cart: cartReducer,
    productStore: productReducer,
    categoryStore:productCategoryReducer,
    user:userReducer,
    users:usersReducer,
    register:registerSliceReducer,
    profile:profileReducer,
    orderStore:salesOrderReducer,

});
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});
export const persistor = persistStore(store)
