import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {patchProfile } from '../store/userProfileSlice';
import styles from './update-profile.module.css';


const UpdateProfile = (props) => {
  const {data:profile} = useSelector((state) => state.profile);
  const [name, setName] = useState(profile?.name);
  
  const [gender, setGender] = useState(profile?.gender);
  const [birthdate, setBirthdate] = useState(profile?.birthDate);
  const [anniversary, setAnniversary] = useState(profile?.anniversary);
  const [phone, setPhone] = useState(profile?.phone);
  const [billing, setBilling] = useState({
    address:profile?.billing?.address,
    city:profile?.billing?.city,
    postalCode:profile?.billing?.postalCode,
    country:profile?.billing?.country,

  });

  const userRegister = useSelector((state) => state.user);
  const { data } = userRegister;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    console.log('billing object in submit handler',billing);
    let formData = new FormData();
      formData.append("_id",data._id);
      formData.append("name",name);
      formData.append("billing",JSON.stringify(billing));
      formData.append("birthDate",birthdate);
      formData.append("gender",gender);
      formData.append("anniversary",anniversary);      
      formData.append("phone",phone);
      try{
        dispatch(patchProfile(formData)).unwrap();
        
      }
      catch(err){
        console.error("Failed to save the category", err);

      }
        

  };
  return (
    
    <div className={`${styles.updateProfile} container`}>
    <form className="form" onSubmit={submitHandler}>
    <div>
      <h1>Update Profile</h1>
    </div>
    
    
    <div >
      <label htmlFor="name">Name</label>
      <input
        type="text"
        id="name"
        className='form-control'
        placeholder="Enter Name"
        value={name}
        required
        onChange={(e) => setName(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="gender">Gender</label>
      <input
        type="text"
        id="gender"
        className='form-control'
        placeholder="Enter gender"
        value={gender}
        
        onChange={(e) => setGender(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="birthdate">Birth Date</label>
      <input
        type="date"
        id="birthdate"
        className='form-control'
        placeholder="Enter birthdate"
        value={birthdate ? moment(birthdate).format('YYYY-MM-DD') : ''}

        
        onChange={(e) => setBirthdate(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="anniversary">Anniversary</label>
      <input
        type="date"
        id="anniversary"
        className='form-control'
        placeholder="Enter anniversary"
        value={anniversary ? moment(anniversary).format('YYYY-MM-DD'):''}
        
        onChange={(e) => setAnniversary(e.target.value)}
      ></input>
    </div>
    <div>
      <label htmlFor="address">Address</label>
      <input
        type="text"
        id="address"
        className='form-control'
        placeholder="Enter Address"
        value={billing.address}
        required
        onChange={(e) => setBilling({...billing,address:e.target.value})}
      ></input>
    </div>
    <div>
      <label htmlFor="city">City</label>
      <input
        type="text"
        id="city"
        className='form-control'
        placeholder="Enter City"
        value={billing.city}
        required
        onChange={(e) => setBilling({...billing,city:e.target.value})}
      ></input>
    </div>
    <div>
      <label htmlFor="postalcode">Postal Code</label>
      <input
        type="text"
        id="postalcode"
        className='form-control'
        placeholder="Enter Postal Code"
        value={billing.postalCode}
        required
        onChange={(e) => setBilling({...billing,postalCode:e.target.value})}
      ></input>
    </div>
    <div>
      <label htmlFor="country">Country</label>
      <input
        type="text"
        id="country"
        className='form-control'
        placeholder="Enter Country"
        value={billing.country}
        required
        onChange={(e) => setBilling({...billing,country:e.target.value})}
      ></input>
    </div>
    <div>
      <label htmlFor="phone">Contact Phone</label>
      <input
        type="text"
        id="phone"
        className='form-control'
        placeholder="Enter Phone"
        value={phone}
        required
        onChange={(e) => setPhone(e.target.value)}
      ></input>
    </div>
    <div>
      <label />
      <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3" type="submit">
        Submit
      </button>
    </div>
    <div>
      <label />
      
    </div>
  </form>

  </div>

  )
}

export default UpdateProfile