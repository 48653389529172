import React from 'react'


const SalesDetailModel = (props) => {
    console.log('sales detail props',props);
  return (
    <div className='container'>
      <h4>Shipping Details</h4>
      <div className='card'>
       <table>
        <tr><td className='fw-bold'>Customer Name:</td><td>{props.order.shipping.fullName}</td></tr>
      <tr><td className='fw-bold'>Address:</td><td>{props.order.shipping.address}</td></tr>
      <tr><td className='fw-bold'>City:</td><td>{props.order.shipping.city}</td></tr>
      <tr><td className='fw-bold'>Postal Code:</td><td>{props.order.shipping.postalCode}</td></tr>
      <tr><td className='fw-bold'>Phone:</td><td>{props.order.shipping.phone}</td></tr>
      
      </table>
      </div>
      <h4>Products Ordered</h4>
      <div className='card'>
        <table>
      {props.order.products.map((item)=>( 
        
          <tbody key={item._id} style={{borderBottom:'1px solid #FFD700'}}>
        <tr>
          <td rowSpan={5}>
        <img src={`https://backend.bankajewellers.com/mobile-${item.image}`} 
        style={{maxHeight:'120px'}} alt={item.image} />
        </td>
        </tr> 
        <tr><td>{item.name}</td></tr>
        <tr><td>Price: &#8377;{item.price.toFixed(2)}</td></tr>
        <tr><td>Quantity: {item.quantity}</td></tr>
        <tr><td>Item Total: &#8377;{((item.price*item.quantity)).toFixed(2)}</td></tr>
        
        </tbody>
        
             
      ))};
      
      </table>
      </div>

      
    </div>
  )
}

export default SalesDetailModel