import axios from "axios";
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const salesOrderSlice = createSlice({
    name: 'orders',
    initialState:{
        data: [],
        status: STATUSES.IDLE,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchOrders.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchOrders.fulfilled, (state, action) => {
            console.log(action.payload);           
            state.data=action.payload;
            state.status = STATUSES.IDLE;
        })
        .addCase(fetchOrders.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        .addCase(getUserOrders.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(getUserOrders.fulfilled, (state, action) => {
            console.log(action.payload);           
            state.data=action.payload;
            state.status = STATUSES.IDLE;
        })
        .addCase(getUserOrders.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        .addCase(updateOrderStatus.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(updateOrderStatus.fulfilled, (state, action) => {
            console.log(action.payload);
            // return state.push([action.payload.data.result]);
            state.status = STATUSES.IDLE;
        })
        .addCase(updateOrderStatus.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        .addCase(deleteOrder.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(deleteOrder.fulfilled, (state, action) => {
            console.log('in delete order slice',action.payload._id);
            // return state.push([action.payload.data.result]);
            state.data = state.data.filter((item) => item._id !== action.payload._id)
            state.status = STATUSES.IDLE;
        })
        .addCase(deleteOrder.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        
    },
});

export const { setOrder, setStatus } = salesOrderSlice.actions;
export default salesOrderSlice.reducer;

// Thunks
export const fetchOrders = createAsyncThunk('getOrders/fetch', async () => {
    console.log('thunk function is reached in orderslice');
    const res = await axios.get('https://backend.bankajewellers.com/api/orders');
    console.log('axios response',res.data.orders)
    //const data =  res;
    //console.log(data.productCategory);
    return res.data.orders;
});
export const getUserOrders = createAsyncThunk('getUserOrders/fetch', async (id) => {
    console.log('thunk function is reached in orderslice');
    const res = await axios.get(`https://backend.bankajewellers.com/api/orders/${id}`);
    console.log('axios response',res.data.orders)
    //const data =  res;
    //console.log(data.productCategory);
    return res.data.orders;
});
export const updateOrderStatus = createAsyncThunk('order/fetch', async () => {
    
    const res = await fetch('https://backend.bankajewellers.com/api/orders',{
        method:'Post'
    });
   //console.log(res)
    const data =  res;
    //console.log(data.productCategory);
    return data.orders;
});
export const deleteOrder = createAsyncThunk('order/delete', async ({id:_id}) => {
    
    const res = await axios.delete('https://backend.bankajewellers.com/api/orders',{
        data:{id:_id}
    });
   //console.log(res)
    
   // console.log(res.data.order);
    return res.data.order;
});



// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
