import React from 'react'
import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from '../../store/usersSlice';

import { Modal } from 'react-bootstrap';
import { FaEdit, FaTrash,FaEye } from "react-icons/fa";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import UserProfile from '../UserProfile';
import EditUser from './EditUser';



const UsersList = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isUserModalOpen, setIsUserModalOpen] = useState(false); 
    const [selectedProfile,setSelectedProfile] = useState('');
    const [selectedUser,setSelectedUser] = useState('');
    const {data:users} = useSelector((state) => state.users);
   
    useEffect(() => {               
        dispatch(getUsers());
        
      },[dispatch]);

      
    const handleView=(e,id)=>{
      e.preventDefault()
      
      setSelectedProfile(id);
      setIsModalOpen(true);
    };
    const handleEdit=(e,id)=>{
      e.preventDefault();
      const result = users.find((x) => x._id === id);      
      setSelectedUser(result);
      
      setIsUserModalOpen(true);
    };
    const handleDelete=()=>{

    }
  return (
    <div className='container'>
      <h3>Users</h3>
      <>
  
  {isModalOpen && <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <UserProfile profile={selectedProfile} closeModal={() => setIsModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
    </>
    <>
    {isUserModalOpen && <Modal show={isUserModalOpen} onHide={() => setIsUserModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>User Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <EditUser selectedUser={selectedUser} closeModal={() => setIsUserModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
  </>
      <Table className='mt-3'>
        <Thead>
          <Tr>
          <Th>id</Th>
          <Th>Name</Th>
          <Th>Email</Th>
                 
            <Th>User Type</Th>
            <Th></Th>
            
          </Tr>
        </Thead>
        <Tbody>
      {users &&
        
        users.map((user,index) => {
         
         // console.log(order.userId);
         
         
         
        return(        
            <Tr key={index}>
              <Td>{user._id} </Td>
              
              
              <Td>{user?.name}</Td>
              <Td>{user?.email}</Td>
              
              <Td>{user.isAdmin?'admin':'customer'}</Td>
              <Td>
              <FaEye style={{marginRight:'13px',cursor:'pointer'}} onClick={(e) => handleView(e, user._id)} />
                <FaEdit style={{cursor:'pointer'}} onClick={(e) => handleEdit(e, user._id)} />
                <FaTrash style={{cursor:'pointer'}}
                  onClick={(e) => handleDelete(e, user._id)}
                  className="ms-3"
                />
              </Td>
            </Tr>
          
      )})}
    </Tbody>

      </Table>
      
    </div>
  )
}

export default UsersList