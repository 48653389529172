const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
    name: 'cart',
    initialState:{
        data:[],
        shipping:[],
        status:''
    },
    reducers: {
        add(state, action) {
            console.log('cart slice add payload',action.payload)
            const newItem = action.payload;
            
            console.log('newitem-before',newItem)
            const existingItem = state.data.find((item) => item._id === newItem._id);
            console.log('newitem',newItem)
            //action.payload[0].push({qty:1});
            //state.data.push(action.payload[0]);
            if (!existingItem) {
                state.data.push({
                  _id: newItem._id,
                  image:(newItem.image !== undefined)?newItem.image:newItem.images[0].filename,
                  price: newItem.price,
                  quantity: (newItem.quantity !==undefined)?newItem.quantity:1,
                  totalPrice: (newItem.price) * ((newItem.quantity !==undefined)?newItem.quantity:1),
                  name: newItem.name,
                });
              } else {
                existingItem.quantity++;
                existingItem.totalPrice = existingItem.totalPrice + newItem.price;
              }
        },
        deduct(state,action){
            const existingItem = state.data.find((item) => item._id === action.payload);
            console.log('deduct existing item',existingItem)
            if(existingItem.quantity > 1){
                existingItem.quantity--;
                existingItem.totalPrice = existingItem.totalPrice - existingItem.price;

            }else{
                state.data= state.data.filter((item) => item._id !== action.payload);
            }

        },
        remove(state, action) {
            console.log(action.payload)
            
            state.data= state.data.filter((item) => item._id !== action.payload);
            console.log(state.data);
        },
        clean(state, action) {
           // console.log(action.payload)
            
            state.data= [];
            console.log(state.data);
        },
        addShipping(state,action){
            state.shipping = action.payload

        }
    },
});

export const { add,deduct,clean, remove,addShipping } = cartSlice.actions;
export default cartSlice.reducer;
