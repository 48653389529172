import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
//import { Link, useNavigate, useLocation, Form } from 'react-router-dom';
import { registerUser } from '../store/registerSlice';
import toast from 'react-hot-toast';



export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success,setSuccess]=useState(false);

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Password and confirm password are not match');
    } else {
      dispatch(registerUser({name:name,email:email,password:password})).then((res) => {
        console.log(res);
        if(res.payload.code===200){
          setName("")
          setEmail("")
          setPassword("")
          setConfirmPassword("")
          setSuccess(true);
          toast.success('Your Registration is successful');  
          }
    });
      /*      
      dispatch(registerUser({name:name,email:email,password:password},(res)=>{
        console.log('register submit handler response',res);
        if(res.status===200){
        setName("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setSuccess(true);
        toast.success('Your Registration is successful');

        }

    })).unwrap()
    */
  }
};

  
  return (
    <div className='container Row'>
      <form className="form" onSubmit={submitHandler}>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <h1>Create Account</h1>
        </div>
        
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="name">Display Name</label>
          <input
            type="text"
            id="name"
            placeholder="Enter name"
            required
            className='form-control'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            id="email"
            placeholder="Enter email"
            required
            className='form-control'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter password"
            required
            className='form-control'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="Enter confirm password"
            required
            className='form-control'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>
        <div className='col-sm-12 col-lg-4 offset-4'>
          
          <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3" type="submit">
            Register
          </button>
          {success && <p style={{color:'green'}}>A Verification mail is sent to the email Id you used for registration</p>}
        </div>
        <div>
          
          
        </div>
      </form>
    </div>
  );
}
