import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../store/userProfileSlice';

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();  

  const {data:user} = useSelector((state) => state.user);
  const {data:profile} = useSelector((state) => state.profile);
  
  
  useEffect(()=>{
    dispatch(getProfile(user._id))
  },[dispatch,user._id]);
  
  const handleUpdateProfile = () => {
    console.log('handleupdate is reached');
    // your custom logic here
    navigate('/user/update-profile');
  };
  return (

    <div>
      <button 
      onClick={handleUpdateProfile}
      className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3" type="submit">
        Update Profile
      </button>
      <h3>MY PROFILE</h3>
      {profile !==undefined && 
        <table style={{backgroundColor:"#fff",padding:"50px",minWidth:"350px"}}>
          <tbody>
          <tr>
            <td>Name:</td><td>{profile?.name}</td>
            </tr>
            <tr>
            <td>Birth Date:</td><td>{moment(profile?.birthDate).format('DD/MMM/YYYY')}</td>
            </tr>
            <tr>
            <td>Anniversary:</td><td>{moment(profile?.anniversary).format('DD/MMM/YYYY')}</td>
            </tr>
            <tr>
            
            <td>Gender:</td><td>{profile?.gender}</td>
            </tr>
            <tr>
            <td>Phone:</td><td>{profile?.phone}</td>
            </tr>
            <tr>
            <td>Address:</td><td>{profile?.billing.address}<br/>
            {profile?.billing.city}<br/>
            {profile?.billing.postalCode}<br/>
            {profile?.billing.country}
            </td>    
          </tr>
          </tbody>
        </table>
      }
      
      
      </div>
  )
}

export default MyProfile