import React from 'react';
import moment from 'moment';
import { useState,useEffect } from 'react';
import {  useDispatch,useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { Modal } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { getUserOrders } from '../store/salesOrderSlice';
import SalesDetailModel from './admin/SalesDetailModel';


const OrderHistory = () => {
  
  const { data: user } = useSelector((state) => state.user);
  const { data: orderStore } = useSelector((state) => state.orderStore);
  const [selectedOrder, setSelectedOrder] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleView=(e,id)=>{
    e.preventDefault();      
    const result= orderStore.find((x) => x._id === id)
    console.log('result in handle view orderHistory',result);
    setSelectedOrder(result);
    setIsModalOpen(true);

   };
   const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getUserOrders(user._id))
  },[dispatch,user])
  return (
    <div>
      <>
  
  {isModalOpen && <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>Sales Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <SalesDetailModel order={selectedOrder} closeModal={() => setIsModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
  </>
      <h3>OrderHistory</h3>
    <Table className='mt-3'>
        <Thead>
          <Tr>
          <Th>Order Id</Th>
          <Th>Date</Th>
            
            <Th>Total Amount</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
      {orderStore !== undefined &&
        Array.isArray(orderStore) && 
        orderStore.map((order,index) => {          
        return(        
            <Tr key={index}>
              <Td>{order._id} </Td>
              
              <Td>{moment(order.updatedAt).format('DD/MM/YYYY hh:mm')}</Td>
             
              
              <Td>&#8377;{(order.total/100).toFixed(2)}</Td>
              <Td>
              <FaEye style={{marginRight:'10px',cursor:'pointer'}} 
              onClick={(e) => handleView(e, order._id)}
              title="View Details"
              />
                
              </Td>
            </Tr>
          
      )})}
    </Tbody>

      </Table>
    </div>
  )
}

export default OrderHistory