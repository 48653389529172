import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector} from "react-redux";

import { fetchProducts,deleteProduct } from "../../store/productSlice";

import { Modal } from 'react-bootstrap';



import { FaEdit, FaTrash } from "react-icons/fa";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./admin.scss";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";



const Product = () => {
  const dispatch = useDispatch();

  const { data: productStore } = useSelector((state) => state.productStore);

  
  const [product,setProduct]=useState({});
  
  const [editMode] = useState(false);
  
  const [setAddRequestStatus] = useState('idle');
  
  const [isModalOpen, setIsModalOpen] = useState(false);

 
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch,productStore.data]);
 // const prevProductStoreRef = useRef(productStore.data);



  const handleEdit = (e, _id) => {
    e.preventDefault();
    console.log('handle edit',_id)
    setIsModalOpen(true)       
    const result = productStore.find((x) => x._id === _id);
    
    setProduct(result);
    
  };
  const handleDelete = (e, _id) => {
    e.preventDefault();
    
    try {
      setAddRequestStatus('pending')
      dispatch(deleteProduct({id:_id})).unwrap()
      
  } catch (err) {
      console.error('Failed to delete the category', err)
  } finally {
      setAddRequestStatus('idle')
  }
  };
  
  return (
    <div className="container">
       <>
  
  {isModalOpen && <Modal className="modal-xl" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <EditProduct product={product} closeModal={() => setIsModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
  </>
      {editMode === false && <AddProduct />}
      

      <Table className='mt-3'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Brand</Th>
            <Th>Category</Th>
            <Th>Price</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {productStore  &&
            productStore.map((item) => (
              <Tr key={item?._id}>
                <Td>{item?.name}</Td>
                <Td>{item?.brand}</Td>
                <Td>{item?.category && item?.category.join(', ')}</Td>
                <Td>{item?.price}</Td>
                <Td>
                  <FaEdit onClick={(e) => handleEdit(e, item._id)} />
                  <FaTrash
                    onClick={(e) => handleDelete(e, item._id)}
                    className='ms-3'
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default Product;
