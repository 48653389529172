import axios from "axios";
import {socketConnect} from "../components/socketConnect";


const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const productSlice = createSlice({
  name: "productStore",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
     setProducts(state, action) {
      console.log('setProducts action paylod',action.payload)
      state.data.forEach((item, index) => {
        action.payload.forEach(payloadItem => {
          if (item._id === payloadItem._id) {
            state.data[index].basePrice = payloadItem.basePrice;
            state.data[index].makingCharge = payloadItem.makingCharge;
            state.data[index].gstAmount = payloadItem.gstAmount;
            state.data[index].price = payloadItem.price;
          }
        });
      });
      
    
       
        
    // },
    // setStatus(state, action) {
    //     state.status = action.payload;
    // },
    

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        console.log('fetch products product slice action payload',action.payload)
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchGoldPrice.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchGoldPrice.fulfilled, (state, action) => {
        console.log('fetch products product slice action payload',action.payload)
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchGoldPrice.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(addProduct.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        console.log('in addProduct.fulfilled',action.payload);
        state.data.push(action.payload);
        state.status = STATUSES.IDLE;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(updateProduct.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const existingItem = state.data.findIndex(
          (item) => item._id === action.payload._id
        );
        if (existingItem >= 0) {
          state.data[existingItem] = action.payload;
        }
        state.status = STATUSES.IDLE;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(deleteProduct.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        // const existingItem = state.data.findIndex((item) => item._id === action.payload._id);
        state.data = state.data.filter(
          (item) => item._id !== action.payload._id
        );
        state.status = STATUSES.IDLE;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setProducts, setStatus } = productSlice.actions;
export default productSlice.reducer;

// Thunks
/*
export const fetchProductsBak = createAsyncThunk("products/fetch", async () => {
  const res = await axios.get(
    "https://backend.bankajewellers.com/api/products"
  );
  // console.log(res)
  // const data = await res.json();
  //  console.log('thunk products',res.data);
  const data = res.data;
  return data.products;
});*/
// Thunks

const socket = socketConnect();
export const fetchProducts = createAsyncThunk(
    "products:get",
    async () => {
      console.log('fetch product before useSocket emit')
      return new Promise((resolve) => {
        socket.emit("products:get", null);
        
        socket.on('products:getSuccess', (data) => {           
            resolve(data.products);
          });
      });
    }
  );
  /*
export const addProduct = createAsyncThunk("product/add", async (formData) => {
  const res = await axios.post(
    "https://backend.bankajewellers.com/api/products",
    formData
  );
  // console.log('add product result',res)
  const data = res.data;
  // console.log('thunk products',data.products);
  return data.message;
});
*/
export const addProduct = createAsyncThunk("product/add", async (formData) => {
  socket.emit("addProduct",formData);
  return new Promise((resolve, reject) => {    
    socket.on("product:added", (res) => {
      console.log(res);
      if(res.code===200){
      resolve(res.message);
      }
    });
  });
  });
  export const updateProduct = createAsyncThunk(
    "product/update",async (formData) => {
      console.log('formdata',formData)
      socket.emit("updateProduct",formData);
    return new Promise((resolve, reject) => {    
      socket.on("product:updated", (res) => {
        console.log(res);
        if(res.code===200){
        resolve(res.message);
        }
      });
    });
    });
/*
export const updateProduct = createAsyncThunk(
  "product/update",
  async (formData) => {
    const res = await axios.patch(
      "https://backend.bankajewellers.com/api/products",
      formData
    );
    //  console.log('axios res',res.data)
    const data = res.data;
    // console.log('thunk products',data);
    return data;
  }
);
*/
export const fetchGoldPrice = createAsyncThunk(
  "fetch/goldrate",
  async (data) => {   
    //console.log('thunk products',data);
    return data;
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete",
  async ({ id: _id }) => {
    const res = await axios.delete(
      "https://backend.bankajewellers.com/api/products",
      { data: { id: _id } }
    );
    // console.log('axios res',res.data.product)
    const data = res.data.product;
    //console.log('thunk products',data);
    return data;
  }
);

// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
