import React from 'react';
import moment from 'moment';
import { useState,useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders,deleteOrder } from '../../store/salesOrderSlice';
import { getUsers } from '../../store/usersSlice';

import { Modal } from 'react-bootstrap';
import { FaEdit, FaTrash,FaEye } from "react-icons/fa";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import SalesDetailModel from './SalesDetailModel';


const SalesOrders = () => {
    const dispatch = useDispatch();          
    useEffect(() => {
        dispatch(getUsers());      
        dispatch(fetchOrders());        
      },[dispatch]);
    const { data: orderStore } = useSelector((state) => state.orderStore);
    const { data: users } = useSelector((state) => state.users);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ setAddRequestStatus] = useState('idle');
    console.log(orderStore);

    
    const handleView=(e,id)=>{
      e.preventDefault(); 
      console.log('handleview id',id);     
      const result= orderStore.find((x) => x._id === id)
      console.log('handleview result',result);
      setSelectedOrder(result);
      setIsModalOpen(true);

     };
      
    const handleEdit=()=>{ };
    const handleDelete=(e,_id)=>{
      e.preventDefault();
    
    try {
      setAddRequestStatus('pending')
      dispatch(deleteOrder({id:_id})).unwrap();
      toast.success('Order item deleted successfully.')
      
  } catch (err) {
      console.error('Failed to delete the category', err)
  } finally {
      setAddRequestStatus('idle')
  }
    };  
  return (
    <div className='container'>
      <>
  
  {isModalOpen && <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>Sales Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <SalesDetailModel order={selectedOrder} closeModal={() => setIsModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
  </>
      
      <h3>Orders</h3>

    <Table className='mt-3'>
        <Thead>
          <Tr>
          <Th>Order Id</Th>
          <Th>Date</Th>
            <Th>Customer Name</Th>            
            <Th>Total Amount</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
      {orderStore !== undefined &&
        Array.isArray(orderStore) && 
        orderStore.map((order,index) => {
         
          console.log(order.userId);
          const customer = users.find((x) => x._id === order.userId);
         
         
        return(        
            <Tr key={index}>
              <Td>{order._id} </Td>
              
              <Td>{moment(order.createdAt).format('DD/MM/YYYY hh:mm')}</Td>
              <Td>{customer?.name}</Td>
              
              <Td>&#8377;{(order.total/100).toFixed(2)}</Td>
              <Td>
              <FaEye style={{marginRight:'13px',cursor:'pointer'}} onClick={(e) => handleView(e, order._id)} />
                <FaEdit style={{cursor:'pointer'}} onClick={(e) => handleEdit(e, order._id)} />
                <FaTrash style={{cursor:'pointer'}}
                  onClick={(e) => handleDelete(e, order._id)}
                  className="ms-3"
                />
              </Td>
            </Tr>
          
      )})}
    </Tbody>

      </Table>
    </div>
  )
}

export default SalesOrders