import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainHeader from './MainHeader';
import Cart from '../pages/Cart';
import Home from '../pages/Home';
import Shop from './Shop';
import Login from '../pages/Login';
import MainFooter from './MainFooter';
import ProductDetail from '../pages/ProductDetail';
import Shipping from './Shipping';
import Register from './Register';
import PaymentMethod from './PaymentMethod';
import CheckoutSuccess from './CheckoutSuccess';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';
import { AboutUs } from '../pages/AboutUs';


const CustomerLayout = () => {
  return (
    <div>
    <MainHeader />  
        <Routes>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/product-detail/:slug" element={<ProductDetail />}></Route>
        <Route path="/cart" element={<Cart />}></Route> 
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
       
        <Route path="/shipping" element={<Shipping />}></Route>
        <Route path="/payment" element={<PaymentMethod />}></Route>
        
        <Route path="/checkout-success" element={<CheckoutSuccess />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/reset-password/:email/:otp" element={<ResetPassword />}></Route>    
        <Route path="/" element={<Home />}></Route>   
        <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found etc</h2>
              </div>
            }
          />
        </Routes>
    
    <MainFooter />
    </div>
  )
}

export default CustomerLayout