import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from '../store/userProfileSlice';
import { useNavigate } from 'react-router-dom';
import { addShipping } from '../store/cartSlice';
import { Modal } from 'react-bootstrap';
import AddShippingAddress from './AddShippingAddress';



const AddressList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: profiles } = useSelector((state) => state.profile);
    const { data: user } = useSelector((state) => state.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [error, setError] = useState(null);
    console.log(profiles);

    useEffect(() => {
        dispatch(getProfile(user._id));
      }, [dispatch,user._id]);

    const selectedAddress=(e,el)=>{
      e.preventDefault();      
      dispatch(addShipping(el));
    }
    const addAddress=(e)=>{
      e.preventDefault(); 
      setIsModalOpen(true);
    }
    const NavigateToPayment=()=>{
      
      navigate('/payment');
    };
    const handleClick = () => {
      if (selected === null) {
        setError('Please select an address');
        console.log('clicked');
      } else {
        NavigateToPayment();
      }
    }
    

  return (
    <div className='container row'>
      <div className='col-4' onClick={addAddress}>
        <div className='card p-3 mb-3' 
        style={{cursor:'pointer',backgroundColor:'#333',color:'#fff',padding:'20px'}}>
        <h3>Add Address</h3> 
        </div>
               
      </div>
      {isModalOpen && <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
  <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <AddShippingAddress closeModal={() => setIsModalOpen(false)} />
    </Modal.Body>
    </Modal>
   
    }
     
       {profiles && profiles.shipping !== null && profiles.shipping?.map((el) => {
        const handleSelected = (e, id) => {
          if (e.target.tagName === 'DIV') {
          setSelected(prevSelected => {
          return prevSelected === id ? null : id;
          });
          } else if (e.target.tagName === 'BUTTON') {
          setSelected(id);
          }
          selectedAddress(e, el);
          }
         return <div className='col-4' >
            <div className={`card p-3 mb-3 ${selected === el._id ? 'selected' : ''}`} onClick={(e)=>handleSelected(e,el._id)}>
            <div>{el?.fullName}</div>
            <div>{el?.address}</div>
            <div>{el?.city}</div>
            <div>{el?.postalCode}</div>
            <div>{el?.country}</div>
            <div>{el?.phone}</div>
            <button className="c-button c-button--full c-button--large c-button--accent button alt mt-3" 
             type='button'
             onClick={(e)=>handleSelected(e,el._id)}>
                     Deliver to this Address
                   </button>
          </div>
          
          </div>
         
         
         
         
        })
       }
       <button 
        onClick={handleClick} 
        
        id="addresslist" 
        type="button" 
        className="c-button c-button--full c-button--large c-button--accent button alt mt-3 mb-3"
      >
        Proceed to Payment
      </button>
      {console.log(error)}
      {error && <div style={{color: '#dc3545',fontSize:'.875em',marginTop: '0.25rem', width: '100%'}}>{error}</div>}
        </div>
  )
}

export default AddressList
