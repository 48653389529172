import React from 'react';

import ProductGallery from '../components/ProductGallery';

const ProductDetail = () => {
   

  return (
    <div>
      
        <ProductGallery />
    </div>
    
  )
}

export default ProductDetail