import React from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';

const PaymentMethod = () => {
  const { data: user } = useSelector((state) => state.user);
  const { data: cart } = useSelector((state) => state.cart);
  const { shipping } = useSelector((state) => state.cart);

  const handleStripePayment=(e)=>{
    e.preventDefault();
    console.log(user)
    console.log('stripe payment');
    axios.post('https://backend.bankajewellers.com/api/stripe/create-checkout-session',{
      cart,
      shipping,
      user:{userId:user._id,email:user.email}
    })
    .then((res)=>{
      if(res.data.url){
        window.location.href = res.data.url;

  }})
  .catch((err)=>{
    console.log(err.message);
  })
    };
  return (
    <div className='Row container'>
      <h2 className='text-center'>Payment Methods</h2>
      <div className='col-3 border border-primary text-center mb-3' role="button"
      onClick={(e)=>handleStripePayment(e)}
      >
        <img src='/assets/Stripe-logo/Stripe wordmark - slate.svg' alt='stripe' width='200px' />
      </div>
    </div>
  )
}

export default PaymentMethod