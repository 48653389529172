import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";
import {
  addProductCategory,
  getProductCategory,
  updateProductCategory,
  deleteProductCategory,
} from "../../store/productCategorySlice";

//import { getProductCategory } from '../../store/getProductCategorySlice';

import { Button } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const AddCategory = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState();
  const [ setAddRequestStatus] = useState("idle");
  const [id, setId] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState();
  const { data: productCategory } = useSelector(
    (state) => state.categoryStore
  );
  const [isUpdate,setIsUpdate]=useState(false);
  //console.log("state.productCategory", productCategory);

  useEffect(() => {
    dispatch(getProductCategory());
  });
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  const handleImage = async (event) => {
    try {
      const images = event.target.files;
      console.log("images in handleImage", images);
      const result = await resizeFile(images[0]);
      console.log(result);
      setFile(result);
      
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    console.log("event id", id);
    const result = productCategory.find((x) => x._id === id);
    console.log("result", result);
    setCategory(result.categoryName);
    setImage(result.categoryImage);
    setId(result._id);
    setIsUpdate(true);
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    console.log("delete-category", id);
    try {
      setAddRequestStatus("pending");
      dispatch(deleteProductCategory({ _id: id })).unwrap();
    } catch (err) {
      console.error("Failed to delete the category", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(category);
    try {
      setAddRequestStatus("pending");
      const formData = new FormData();
      formData.append('productCategory', category);
      formData.append('categoryImage', file);
      if(!isUpdate){
      dispatch(addProductCategory(formData )).unwrap();
      }else if(isUpdate){
        formData.append('_id',id);
        dispatch(updateProductCategory(formData )).unwrap();
      }
      setCategory("");
      setFile(null);
      setImage(null);
    } catch (err) {
      console.error("Failed to save the category", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };




  return (
    <div className='container row'>
      <h1>Add Category</h1>
      <form onSubmit={handleSubmit}>
        <div className='form-group mb-3 col-lg-6'>
        <label>Category Name</label>
          <input
            type='text'
            className='form-control'
            name='categoryName'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        {isUpdate &&
        <img
        style={{ padding: "10px" }}
        width={150}
        src={
          image
            ? `https://backend.bankajewellers.com/${image.filename}`
            : null
        }
        alt=''
      />
        }
        <div className='col-12 col-lg-12 mb-3 mt-3'>
          <label>Upload Category Image</label>
          <span>
            {console.log(file)}
            <img
              style={{ padding: "10px" }}
              width={150}
              src={file ? URL.createObjectURL(file) : null}
              alt=''
            />
          </span>
        </div>
        <div className='form-group mb-3 col-lg-6'>
          <input
            type='file'
            name='categoryImage'
            className='form-control'
            onChange={handleImage}
          />
        </div>
        <Button type='submit' className='btn btn-primary mt-3 mb-3'>
          Save
        </Button>
      </form>

      
      <Table className='mt-3'>
        <Thead>
          <Tr>
            <Th>Category id</Th>
            <Th>Category Name</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {productCategory !== "" &&
            productCategory.map((item) => (
              <Tr key={item._id}>
                <Td>{item._id}</Td>
                <Td>{item.categoryName}</Td>
                <Td>
                  <FaEdit onClick={(e) => handleEdit(e, item._id)} />
                  <FaTrash
                    onClick={(e) => handleDelete(e, item._id)}
                    className='ms-3'
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default AddCategory;
