import React from "react";

export const AboutUs = () => {
  return (
    <div className="container">
      <div className='about-wrapper' >
        <div className='std'>
          <div className='page-title'>
            <h1>About Banka Jewellers</h1>
          </div>
          <div className='col3-set'>
            <div>
              <p className='about-us'>
                
                  We are the Manufacturers and suppliers of Gold &amp; Diamond
                  Jewellery. Our Jewellery meets the highest quality of
                  workmanship in Design reflecting the blend of Modern and
                  Traditional Mix.
                
              </p>
              <p  className='about-us'>
                The name <b>Banka Jewellers</b> is enough to generate confidence
                in our customers while dealing with us and be assured about the
                quality and purity of our product..
              </p>
            </div>
            <div>
              <p className='about-us'>
                <strong>
                  As Rome was not built in a day. The Goodwill earned by Banka
                  Jewellers is the result of hard work, fair dealing and meeting
                  the expectation of its customers for over 5 decades.
                </strong>
              </p>
              <p className='about-us'>
                Though we enjoy the goodwill and patronized by our customer, we
                do not sit on our laurels. We constantly en-devour to make new
                innovation to improve our quality of service and introduce new
                designs keeping up with the changing trends. The satisfaction of
                our customer is our biggest reward.
              </p>
            </div>
            <div>
              <p className='about-us'>
                Over the years the name <b>"BANKA"</b> has become the symbol of
                Trust. We take pride, when our customer show their trust in us
                and we are thankful to them for reposing their confidence in us.
              </p>
              <p className='about-us'>
                <strong>
                  We strongly believe that this website will be another
                  milestone in serving our customer better and keeping them
                  informed of the latest products and designs available for any
                  occassion.
                </strong>
              </p>

              <div className='divider'></div>
              <p className='about-us'>
                To all of you, from all of us at Banka Jewellers - Thank you!
              </p>
              <p className='about-us'>
                <strong>Anil Kumar Banka</strong>
                <br />
                <small>Owner</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
